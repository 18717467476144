import { Checkbox, Col, Form, Input, Modal, Radio, Row, message } from "antd";
import { useState } from "react";
import OwlCarousel from "react-owl-carousel";
import { Link } from "react-router-dom";
import clientService from "../../../services/http-client";
import { LoginImg, logo } from "../../imagepath";

const Register = () => {
  const [confirmed, setConfirmed] = useState(false);
  const [form] = Form.useForm();
  var settings = {
    items: 2,
    margin: 25,
    dots: true,
    nav: true,
    navText: ['<i className="fas fa-arrow-left"></i>', '<i className="fas fa-arrow-right"></i>'],

    loop: true,
    responsiveClass: true,
    responsive: {
      0: {
        items: 1,
      },
      768: {
        items: 1,
      },
      1170: {
        items: 1,
      },
    },
  };
  const onFinish = async (value) => {
    try {
      await clientService.api.authRegister(value);
      message.success("Ký thành công thành công !");
      window.location.href = `/`;
    } catch (e) {
      console.log(e);
      if (e.errors) {
        form.setFields(e.errors);
      } else {
        Modal.error({
          title: "Lỗi đăng ký",
          content: "Vui lòng kiểm tra lại các thông tin",
        });
      }
    }
  };
  return (
    <>
      <div className="main-wrapper log-wrap">
        <div className="row">
          {/* Login Banner */}
          <div className="col-md-6 login-bg">
            <OwlCarousel {...settings} className="owl-carousel login-slide owl-theme">
              <div className="welcome-login">
                <div className="login-banner">
                  <img src={LoginImg} className="img-fluid" alt="Logo" />
                </div>
                <div className="mentor-course text-center">
                  <h2>
                    Đào tạo trực tuyến <br />
                    Viện Y Dược Lâm Sàng
                  </h2>
                  {/* <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                    enim ad minim veniam.
                  </p> */}
                </div>
              </div>
            </OwlCarousel>
          </div>
          {/* /Login Banner */}
          <div className="col-md-6 login-wrap-bg">
            {/* Login */}
            <div className="login-wrapper">
              <div className="loginbox">
                <div className="img-logo">
                  <img src={logo} className="img-fluid" alt="Logo" />
                  <div className="back-home">
                    <Link to="/">Quay lại trang chủ</Link>
                  </div>
                </div>
                <h1>Đăng ký thành viên</h1>
                <Form layout="vertical" size="large" onFinish={onFinish} form={form}>
                  <Row>
                    <Col span={12} xs={24}>
                      <Form.Item name={"at"} label="" rules={[{ required: true, message: "Vui lòng chọn" }]}>
                        <Checkbox.Group style={{ width: 300 }}>
                          <Row style={{ flexFlow: "unset" }}>
                            <Checkbox value={"GiangVien"}>Giảng viên</Checkbox>
                            <Checkbox value={"HocVien"}>Học viên</Checkbox>
                          </Row>
                        </Checkbox.Group>
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={10}>
                    <Col span={16} xs={24}>
                      <Form.Item name="fullName" label="Họ và tên" rules={[{ required: true, message: "Vui lòng nhập họ tên" }]}>
                        <Input />
                      </Form.Item>
                    </Col>
                    <Col span={8} xs={24}>
                      <Form.Item name="gender" label="Giới tính" rules={[{ required: true, message: "Vui lòng chọn giới tính" }]}>
                        <Radio.Group style={{ width: 300 }}>
                          <Row style={{ flexFlow: "unset" }}>
                            <Radio value={"nam"}>Nam</Radio>
                            <Radio value={"nu"}>Nữ</Radio>
                          </Row>
                        </Radio.Group>
                      </Form.Item>
                    </Col>
                  </Row>
                  <Form.Item name="phoneNumber" label="Điện thoại" rules={[{ required: true, message: "Vui lòng nhập số điện thoại" }]}>
                    <Input />
                  </Form.Item>
                  <Form.Item
                    name="email"
                    label="Email"
                    rules={[
                      { required: true, message: "Vui lòng nhập email" },
                      { type: "email", validateTrigger: ["onBlur"], message: "Vui lòng nhập đúng định dạng email" },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item name="password" label="Mật khẩu" rules={[{ required: true, message: "Vui lòng nhập mật khẩu" }]}>
                    <Input.Password />
                  </Form.Item>

                  <div className="form-check remember-me">
                    <label className="form-check-label mb-0">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        name="confirm"
                        onChange={(e) => {
                          setConfirmed(e.target.checked);
                        }}
                      />
                      Đồng ý các &nbsp;
                      <Link to="/chinh-sach">điều khoản</Link>&nbsp; của website
                    </label>
                  </div>
                  <div className="d-grid">
                    <button className="btn btn-start" type="submit" disabled={!confirmed}>
                      Đăng ký tài khoản
                    </button>
                  </div>
                </Form>
              </div>
            </div>
            {/* /Login */}
          </div>
        </div>
      </div>
    </>
  );
};

export default Register;
