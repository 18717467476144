export interface PermissionEntity {
  id: number;
  name: string;
  description: string;
  visible: boolean;
  roles: Role[];
  categoryName: string;
}

export interface LoaiHinhDaoTao {
  id: number;
  ten: string;
  stt: number;
}

export interface DanhMucDaoTao {
  id: number;
  ten: string;
  stt: number;
}

export interface DiaDiem {
  id: number;
  ten: string;
  diaChi: string;
  stt: number;
}

export interface FileEntity {
  /** @example "cbcfa8b8-3a25-4adb-a9c6-e325f0d0f3ae" */
  id: number;
  path: string;
  name: string;
}

export interface ChungChi {
  id: number;
  stt: number;
  ten: string;
  fileMau: FileEntity;
  /** @format date-time */
  ngayCap: string;
  soHieu: string;
  TenNguoiPhuTrach: string;
  chuKyNguoiPhuTrach: FileEntity;
  chucDanhBGDKyChungChi: string;
  tenBGDKyChungChi: string;
  chuKyBGD: FileEntity;
}

export interface HocHam {
  id: number;
  ten: string;
  stt: number;
}

export interface HocVi {
  id: number;
  ten: string;
  stt: number;
}

export interface ChuyenKhoa {
  id: number;
  ten: string;
  stt: number;
}

export interface GiayToGiangVien {
  id: number;
  _id: number;
  ten: string;
  maSo: string;
  /** @format date-time */
  ngayHetHan?: string;
  fileDinhKem: FileEntity;
  giangVien: GiangVien;
}

export interface SubVoucher {
  ma: string;
  nguoiTao: User;
  voucher: Voucher;
}

export interface PhongBan {
  id: number;
  ten: string;
  stt: number;
}

export interface GiayToNhanVien {
  id: number;
  _id: number;
  ten: string;
  maSo: string;
  /** @format date-time */
  ngayHetHan?: string;
  fileDinhKem: FileEntity;
  nhanVien: NhanVien;
}

export interface NhanVien {
  id: number;
  /** @format date-time */
  ngayVaoLam: string;
  /** @format date-time */
  ngayKetThuc: string;
  phongBan: PhongBan;
  giayToDinhKem: GiayToNhanVien[];
  user: User;
  status: 'DangLamViec' | 'DaNghiViec';
}

export interface GiayToHocVien {
  id: number;
  _id: number;
  ten: string;
  maSo: string;
  /** @format date-time */
  ngayHetHan?: string;
  fileDinhKem: FileEntity;
  hocVien: HocVien;
}

export enum LoaiTaiLieuEnum {
  TaiLieu = 'TaiLieu',
  Video = 'Video',
}

export interface TaiLieuHocTap {
  id: number;
  stt: number;
  khoaHoc: KhoaHoc;
  loaiTaiLieu: LoaiTaiLieuEnum;
  tenTaiLieu: string;
  dinhKem: FileEntity;
  tenVideo: string;
  linkVideo: string;
  gioTruyCapToiThieu: number;
  phanTramDat: number;
  /** @format date-time */
  ngayGioHienThi: string;
  /** @format date-time */
  ngayGioDong: string;
  moTaNgan: string;
  moTa: string;
  hienThi: boolean;
}

export interface BoDe {
  id: number;
  ten: string;
  stt: number;
}

export interface DanhMucCauHoi {
  id: number;
  ten: string;
  stt: number;
  cap1: DanhMucCauHoi;
  cap2: DanhMucCauHoi[];
}

export interface DapAn41 {
  id: number;
  STT: string;
  noiDungDapAn: string;
  diem: number;
  ketQuaDung: boolean;
  _id: number;
}

export interface DapAnTracNghiem {
  id: number;
  diem: number;
  ketQuaDung: boolean;
}

export interface DapAnDienKhuyet {
  id: number;
  STT: string;
  noiDungDapAn: string;
  diem: number;
  _id: number;
}

export interface DapAnTuLuan {
  id: number;
  noiDungDapAn: string;
  diem: number;
}

export interface DapAnGhepCap {
  id: number;
  STT: string;
  noiDungCauTraLoi: string;
  _id: number;
}

export interface CauHoiGhepCap {
  id: number;
  STT: string;
  noiDungCauHoi: string;
  diem: number;
  ketQuaDung: string;
  _id: number;
}

export interface CauHoi {
  id: number;
  loaiCauHoi: 'Trắc nghiệm 4 chọn 1' | 'Trắc nghiệm đúng sai' | 'Điền khuyết' | 'Ghép cặp đúng' | 'Tự luận';
  danhMuc1: DanhMucCauHoi;
  danhMuc2: DanhMucCauHoi;
  tenCauHoi: string;
  noiDungCauHoi: string;
  dapAn41: DapAn41[];
  dapAnTracNghiem: DapAnTracNghiem;
  dapAnDienKhuyet: DapAnDienKhuyet[];
  dapAnTuLuan: DapAnTuLuan;
  dapAnGhepCap: DapAnGhepCap[];
  cauHoiGhepCap: CauHoiGhepCap[];
  dapAn: string;
  tongDiem: number;
  _id: number;
  _danhMuc1: number;
  _danhMuc2: number;
  _cauHoiId: number;
}

export interface QuanLyBoDe {
  id: number;
  danhMucBoDe: BoDe;
  tenBoDe: string;
  soCauHoi: number;
  thoiGian: number;
  thangDiem: number;
  diemCanDat: number;
  cauHoi: CauHoi[];
}

export interface QuanLyBoDeThi {
  id: number;
  khoaHoc: KhoaHoc;
  loaiThi: 'Thi đầu vào' | 'Thi giữa kỳ' | 'Thi cuối kỳ';
  danhMucBoDe: BoDe;
  boDeThi: QuanLyBoDe;
  /** @format date-time */
  ngayGioHienThi: string;
  /** @format date-time */
  ngayGioDong: string;
}

export interface QuanLyKiemTra {
  id: number;
  khoaHocId: number;
  hocVienId: number;
  boDeThiId: number;
  khoaHoc: KhoaHoc;
  hocVien: HocVien;
  boDeThi: QuanLyBoDeThi;
  data: object;
  ketQua: object;
  /** @format date-time */
  endTime: string;
  tongDiem: number;
  tongDiemToiDa: number;
  isGiangVienChecked: boolean;
  danhGia: boolean;
  soCauHoi: number;
  thoiGian: number;
  thangDiem: number;
  diemTheoThangDiem: number;
  diemCanDat: number;
  cauHoi: CauHoi[];
  loaiThi: string;
  tenBoDe: string;
  isEnd: boolean;
  /** @format date-time */
  createdDate: string;
}

export enum TrangThaiHocTapEnum {
  DaTruyCap = 'DaTruyCap',
  ChuaTruyCap = 'ChuaTruyCap',
  DaXemVideo = 'DaXemVideo',
  DaLamBai = 'DaLamBai',
}

export enum LoaiTienDoEnum {
  TaiLieu = 'TaiLieu',
  Video = 'Video',
  KiemTra = 'KiemTra',
}

export interface TienDoHocTap {
  id: number;
  taiLieuHocTap: TaiLieuHocTap;
  quanLyKiemTra: QuanLyKiemTra;
  soGioHoc: number;
  khhv: KhoaHocHocVien;
  danhGia: boolean;
  trangThai: TrangThaiHocTapEnum;
  gioTruyCapToiThieu: number;
  loai: LoaiTienDoEnum;
}

export interface KhoaHocHocVien {
  id: number;
  khoaHocId: number;
  hocVienId: number;
  duyetGhiDanh: boolean;
  daDongPhi: number;
  /** @format date-time */
  ngayDongPhi: string;
  nhanVienThu: NhanVien;
  ghiChu: string;
  duDieuKien: boolean;
  capChungNhan: boolean;
  soChungNhan: number;
  /** @format date-time */
  ngayCapCN: string;
  soGioHoc: number;
  isGiuCho: boolean;
  khoaHoc: KhoaHoc;
  hocVien: HocVien;
  tienDoHocTaps: TienDoHocTap[];
  subVoucher: SubVoucher;
  ngayDangKy: string;
  /** @format date-time */
  ngayThanhToan?: string;
  soTien?: number;
  chungTuDinhKem?: FileEntity;
}

export interface HocVien {
  id: number;
  hocHam: HocHam;
  hocVi: HocVi;
  chuyenKhoa: ChuyenKhoa;
  /** @format date-time */
  ngayGhiDanh: string;
  donViCongTac: string;
  giayToDinhKem: GiayToHocVien[];
  status: 'MoiDangKy' | 'DangHocTap' | 'DaKetThuc';
  user: User;
  khhv: KhoaHocHocVien[];
}

export interface User {
  id: number;
  username: string;
  email: string;
  fullName: string;
  role: Role;
  ipAddress: string;
  gender: string;
  /** @format date-time */
  birthdate: string;
  phoneNumber: string;
  cccd: string;
  note: string;
  address: string;
  subVouchers: SubVoucher[];
  giangVien: GiangVien;
  nhanVien: NhanVien;
  hocVien: HocVien;
}

export interface GiangVien {
  id: number;
  hocHam: HocHam;
  hocVi: HocVi;
  chuyenKhoa: ChuyenKhoa;
  /** @format date-time */
  ngayVaoLam: string;
  /** @format date-time */
  ngayKetThuc: string;
  donViCongTac: string;
  giayToDinhKem: GiayToGiangVien[];
  status: 'MoiDangKy' | 'DaKetThuc' | 'DangGiangDay';
  user: User;
}

export interface CoCauNhanSu {
  id: number;
  _id: number;
  chucVu: Role;
  user: User;
  mucLuong: number;
  ghiChu: string;
  khoaHoc: KhoaHoc;
}

export interface TietHocOnline {
  id: number;
  khoaHoc: KhoaHoc;
  hinhThucHoc: string;
  room: string;
  matKhau: string;
  /** @format date-time */
  ngayTrucTuyen: string;
  _id: number;
}

export interface KhoaHoc {
  id: number;
  stt: number;
  loaiHinhDaoTao: LoaiHinhDaoTao;
  danhMucDaoTao: DanhMucDaoTao;
  tenKhoaHoc: string;
  diaDiem: DiaDiem;
  diaChi: string;
  soNgayHoc: number;
  soTiet: number;
  /** @format date-time */
  ngayKhaiGiang: string;
  /** @format date-time */
  ngayKetThuc: string;
  soLuongHocVien: number;
  giaTien: number;
  noiBat?: boolean;
  loaiChungChi: ChungChi;
  hinhAnh: FileEntity;
  moTaNgan: string;
  mota: string;
  giangVien: GiangVien;
  coCauNhanSu: CoCauNhanSu[];
  boDeThi: QuanLyBoDeThi[];
  taiLieuHocTaps: TaiLieuHocTap[];
  khhv: KhoaHocHocVien[];
  tietHocOnlines: TietHocOnline[];
  isHocGiaoTrinh: boolean;
  isKetQuaHocTap: boolean;
}

export enum LoaiGiamEnum {
  GiaTri = 'GiaTri',
  PhanTram = 'PhanTram',
}

export enum LoaiVoucherEnum {
  KhoaHoc = 'KhoaHoc',
  CaNhan = 'CaNhan',
}

export interface Voucher {
  ma: string;
  ten: string;
  khoaHocApDung: KhoaHoc;
  giam: number;
  loaiGiam: LoaiGiamEnum;
  /** @format date-time */
  ngayHieuLuc: string;
  /** @format date-time */
  ngayHetHan: string;
  chietKhau: number;
  loaiChietKhau: LoaiGiamEnum;
  subVouchers: SubVoucher[];
  doiTuongApDungs: Role[];
  giaTriApDung: number;
  loaiVoucher: LoaiVoucherEnum;
}

export interface Role {
  id: number;
  stt: number;
  ten: string;
  deleteable: boolean;
  permissions: PermissionEntity[];
  vouchers: Voucher[];
}

export type PartialType = object;

export interface RoleDto {
  ten: string;
  stt: number;
  visible: boolean;
  permissions: PermissionEntity[];
}

export interface EnrollKhoaHocDto {
  password: string;
  fullName: string;
  birthdate: string;
  maVoucher: string;
  email: string;
  phoneNumber: string;
  address: string;
  donViCongTac: string;
  khoaHoc: KhoaHoc;
}

export interface GiayToHocVienDto {
  _id: number;
  ten?: string;
  maSo?: string;
  /** @format date-time */
  ngayHetHan?: string;
  fileDinhKem?: FileEntity;
}

export enum StatusHVEnum {
  MoiDangKy = 'MoiDangKy',
  DangHocTap = 'DangHocTap',
  DaKetThuc = 'DaKetThuc',
}

export interface UserDto {
  id: number;
  email: object;
  username: object;
  password: string;
  provider: string;
  fullName: string;
  cccd: string;
  avatar: FileEntity;
  role: Role;
}

export interface HocVienDto {
  hocHam: HocHam;
  hocVi: HocVi;
  chuyenKhoa?: ChuyenKhoa;
  /** @format date-time */
  ngayGhiDanh: string;
  donViCongTac: string;
  giayToDinhKem: GiayToHocVienDto[];
  status: StatusHVEnum;
  user: UserDto;
  soYeuLyLich: FileEntity;
}

export interface GiayToNhanVienDto {
  _id: number;
  ten?: string;
  maSo?: string;
  /** @format date-time */
  ngayHetHan?: string;
  fileDinhKem?: FileEntity;
}

export enum StatusNVEnum {
  DangLamViec = 'DangLamViec',
  DaNghiViec = 'DaNghiViec',
}

export interface NhanVienDto {
  /** @format date-time */
  ngayVaoLam: string;
  /** @format date-time */
  ngayKetThuc: string;
  phongBan: PhongBan;
  giayToDinhKem: GiayToNhanVienDto[];
  soYeuLyLich: FileEntity;
  status: StatusNVEnum;
  user: UserDto;
}

export interface GiayToGiangVienDto {
  _id: number;
  ten?: string;
  maSo?: string;
  /** @format date-time */
  ngayHetHan?: string;
  fileDinhKem?: FileEntity;
}

export enum StatusGVEnum {
  MoiDangKy = 'MoiDangKy',
  DaKetThuc = 'DaKetThuc',
  DangGiangDay = 'DangGiangDay',
}

export interface GiangVienDto {
  hocHam: HocHam;
  hocVi: HocVi;
  chuyenKhoa?: ChuyenKhoa;
  /** @format date-time */
  ngayVaoLam: string;
  /** @format date-time */
  ngayKetThuc: string;
  donViCongTac: string;
  giayToDinhKem: GiayToGiangVienDto[];
  soYeuLyLich: FileEntity;
  status: StatusGVEnum;
  user: UserDto;
}

export enum TinhTrangVoucherEnum {
  MoiKhoiTao = 'MoiKhoiTao',
  ChuaHieuLuc = 'ChuaHieuLuc',
  DangSuDung = 'DangSuDung',
  DaApDung = 'DaApDung',
  DaKetThuc = 'DaKetThuc',
}

export interface VoucherApDung {
  suDungVoucherId: number;
  subVoucher: SubVoucher;
  nguoiTao: User;
  hocVien: HocVien;
  khoaHoc: KhoaHoc;
  tinhTrang: 'MoiKhoiTao' | 'ChuaHieuLuc' | 'DangSuDung' | 'DaApDung' | 'DaKetThuc';
}

export enum TinhTrangThanhToanVoucherEnum {
  DangThongKe = 'DangThongKe',
  DaChiDu = 'DaChiDu',
  DaThanhToan = 'DaThanhToan',
}

export interface VoucherThongKe {
  thanhVien: User;
  soVoucherSuDung: number;
  tongHoaHong: number;
  tinhTrang: TinhTrangThanhToanVoucherEnum;
  nam: number;
  thang: number;
}

export interface SubVoucherThongKe {
  subVoucher: SubVoucher;
  soNguoiSuDung: number;
  tongHoaHong: number;
}

export interface DiemThuongThanhToan {
  thang: string;
  soVoucherSuDung: number;
  soTienThuong: number;
  tinhTrang?: 'DangThongKe' | 'DaChiDu' | 'DaThanhToan';
  /** @format date-time */
  ngayDuyet?: string;
  chungTu?: FileEntity;
}

export interface VoucherDto {
  ma: string;
  ten: string;
  khoaHocApDung: KhoaHoc;
  giam: number;
  loaiGiam: LoaiGiamEnum;
  doiTuongApDungs: Role[];
  /** @format date-time */
  ngayHieuLuc: string;
  /** @format date-time */
  ngayHetHan: string;
  chietKhau: number;
  loaiChietKhau: LoaiGiamEnum;
  nguoiTao: User;
  giaTriApDung: number;
  loaiVoucher: string;
}

export interface ThanhToanVoucherDto {
  thangThongKe: number;
  namThongKe: number;
  soTien: number;
  user: User;
  /** @format date-time */
  ngayThanhToan: string;
  chungTuDinhKem: FileEntity;
}

export interface ThanhToanVoucher {
  id: number;
  thangThongKe: number;
  namThongKe: number;
  soTien: number;
  isChiDu: boolean;
  user: User;
  /** @format date-time */
  ngayThanhToan: string;
  chungTuDinhKem: FileEntity;
}

export interface SubVoucherDto {
  voucher: Voucher;
}

export interface HocVienGhiDanhDto {
  daDongPhi: number;
  /** @format date-time */
  ngayDongPhi: string;
  nhanVienThu: NhanVien;
  ghiChu: string;
}

export interface QuanLyKiemTraDto {
  data: object;
}

export interface MailKhoaHocDto {
  to: any[][];
  isSendAll: boolean;
  subject: string;
  file?: FileEntity;
  content: string;
}

export interface XacNhanThanhToanDto {
  /** @format date-time */
  ngayThanhToan: string;
  soTien: number;
  chungTuDinhKem: FileEntity;
}

export interface KhoaHocDto {
  loaiHinhDaoTao: LoaiHinhDaoTao;
  danhMucDaoTao: DanhMucDaoTao;
  tenKhoaHoc: string;
  diaDiem: DiaDiem;
  diaChi: string;
  soNgayHoc: number;
  soTiet: number;
  /** @format date-time */
  ngayKhaiGiang: string;
  /** @format date-time */
  ngayKetThuc: string;
  soLuongHocVien: number;
  giaTien: number;
  noiBat: boolean;
  loaiChungChi: ChungChi;
  hinhAnh: FileEntity;
  moTaNgan: string;
  mota: string;
  giangVien: GiangVien;
  coCauNhanSu: CoCauNhanSu[];
  stt: number;
}

export interface QuanLyBoDeThiDto {
  khoaHoc: KhoaHoc;
  loaiThi: 'Thi đầu vào' | 'Thi giữa kỳ' | 'Thi cuối kỳ';
  danhMucBoDe: BoDe;
  boDeThi: QuanLyBoDe;
  /** @format date-time */
  ngayGioHienThi: string;
  /** @format date-time */
  ngayGioDong: string;
}

export interface AuthUsernameLoginDto {
  username: string;
  password: string;
  at: string;
}

export interface LoginResponse {
  token: string;
  refreshToken: string;
}

export interface AuthGoogleLoginDto {
  idToken: string;
}

export interface AuthRegisterLoginDto {
  email: string;
  password: string;
  fullName: string;
  gender: string;
  phoneNumber: string;
  at: string[];
}

export interface AuthForgotPasswordDto {
  email: string;
}

export interface LoaiHinhDaoTaoDto {
  ten: string;
  stt: number;
}

export interface DanhMucDaoTaoDto {
  ten: string;
  stt: number;
}

export interface DiaDiemDto {
  ten: string;
  diaChi: string;
  stt: number;
}

export interface ChungChiDto {
  stt: number;
  ten: string;
  fileMau: FileEntity;
  /** @format date-time */
  ngayCap: string;
  soHieu: string;
  TenNguoiPhuTrach: string;
  chuKyNguoiPhuTrach: FileEntity;
  chucDanhBGDKyChungChi: string;
  tenBGDKyChungChi: string;
  chuKyBGD: FileEntity;
}

export interface HocHamDto {
  ten: string;
  stt: number;
}

export interface HocViDto {
  ten: string;
  stt: number;
}

export interface DanhMucCauHoiDto {
  ten: string;
  stt: number;
  cap1: DanhMucCauHoi;
}

export interface BoDeDto {
  ten: string;
  stt: number;
}

export interface TaiLieuDto {
  ten: string;
  stt: number;
}

export interface TaiLieu {
  id: number;
  ten: string;
  stt: number;
}

export interface PhongBanDto {
  ten: string;
  stt: number;
}

export interface LinhVucHoatDongDto {
  ten: string;
  stt: number;
}

export interface LinhVucHoatDong {
  id: number;
  ten: string;
  stt: number;
}

export enum LoaiThuChi {
  Thu = 'Thu',
  Chi = 'Chi',
}

export interface DanhMucThuChi {
  id: number;
  ten: string;
  loai: LoaiThuChi;
  stt: number;
}

export enum QuyTien {
  TiềnMặt = 'Tiền mặt',
  ChuyểnKhoản = 'Chuyển khoản',
}

export interface GiayToDoiTac {
  id: number;
  _id: number;
  ten: string;
  fileDinhKem: FileEntity;
  doiTac: DoiTac;
}

export interface DoiTac {
  id: number;
  linhVuc: LinhVucHoatDong;
  maSoThue: string;
  tenCty: string;
  tinhThanh: string;
  quanHuyen: string;
  diaChi: string;
  nguoiPhuTrach: string;
  chucVu: string;
  soDienThoai: string;
  email: string;
  website: string;
  tinhTrang: string;
  quanTam: string;
  giayToDinhKems: GiayToDoiTac[];
}

export interface DinhKemPhieuThuChiDto {
  _id: number;
  ten?: string;
  fileDinhKem?: FileEntity;
}

export interface PhieuThuChiDto {
  soPhieu: string;
  danhMucThuChi: DanhMucThuChi;
  /** @format date-time */
  ngay: string;
  quyTien: QuyTien;
  soTien: number;
  doiTac: DoiTac;
  nguoiThucHien: string;
  nhanVien: NhanVien;
  noiDung: string;
  ghiChu: string;
  giayToDinhKems: DinhKemPhieuThuChiDto[];
}

export interface PhieuThuChi {
  id: number;
  soPhieu: string;
  danhMucThuChi: DanhMucThuChi;
  /** @format date-time */
  ngay: string;
  quyTien: QuyTien;
  soTien: number;
  doiTac: DoiTac;
  nguoiThucHien: string;
  nhanVien: NhanVien;
  noiDung: string;
  ghiChu: string;
  giayToDinhKems: DinhKemPhieuThuChi[];
}

export interface DinhKemPhieuThuChi {
  id: number;
  _id: number;
  ten: string;
  fileDinhKem: FileEntity;
  phieuThu: PhieuThuChi;
}

export interface ThongBao {
  id: number;
  tieuDe: string;
  noiDung: string;
  chucVu: Role;
  daDoc: User[];
  ngayGui: string;
  daXem: boolean;
}

export interface DaDocTBDto {
  id: number;
}

export interface ThongBaoDto {
  tieuDe: string;
  noiDung: string;
  chucVu: Role;
}

export interface BaiVietDto {
  tieuDe: string;
  moTa: string;
  noiDung: string;
  hinhAnh: FileEntity;
}

export interface BaiViet {
  id: number;
  tieuDe: string;
  moTa: string;
  noiDung: string;
  hinhAnh: FileEntity;
}

export interface GiayToDoiTacDto {
  _id: number;
  ten?: string;
  fileDinhKem?: FileEntity;
}

export interface DoiTacDto {
  linhVuc: LinhVucHoatDong;
  maSoThue: string;
  tenCty: string;
  tinhThanh: string;
  quanHuyen: string;
  diaChi: string;
  nguoiPhuTrach: string;
  chucVu: string;
  soDienThoai: string;
  email: string;
  website: string;
  tinhTrang: string;
  quanTam: string;
  giayToDinhKems: GiayToDoiTacDto[];
}

export interface LoaiCongVanDi {
  id: number;
  stt: number;
  ten: string;
  soHieu: string;
  noiDung: string;
}

export interface BoPhanBanHanh {
  id: number;
  stt: number;
  ten: string;
}

export interface GiayToCongVanDiDto {
  _id: number;
  ten?: string;
  fileDinhKem?: FileEntity;
}

export interface CongVanDiDto {
  loaiCongVan: LoaiCongVanDi;
  boPhanBanHanh: BoPhanBanHanh;
  soCongVan: string;
  /** @format date-time */
  ngayBanHanh: string;
  /** @format date-time */
  ngayGui?: string;
  nguoiGui: User;
  noiNhan: DoiTac;
  noiDungCongVan: string;
  ghiChu: string;
  giayToCongVan: GiayToCongVanDiDto[];
}

export interface CongVanDi {
  id: number;
  loaiCongVan: LoaiCongVanDi;
  boPhanBanHanh: BoPhanBanHanh;
  soCongVan: string;
  /** @format date-time */
  ngayBanHanh: string;
  /** @format date-time */
  ngayGui?: string;
  nguoiGui: User;
  noiNhan: DoiTac;
  noiDungCongVan: string;
  ghiChu: string;
  giayToDinhKem: GiayToCongVanDi[];
}

export interface GiayToCongVanDi {
  id: number;
  _id: number;
  ten: string;
  fileDinhKem: FileEntity;
  congVanDi: CongVanDi;
}

export interface GiayToCongVanDenDto {
  _id: number;
  ten?: string;
  fileDinhKem?: FileEntity;
}

export interface CongVanDenDto {
  soCongVanDen: string;
  /** @format date-time */
  ngayCongVanDen: string;
  nguoiGui: DoiTac;
  donViTiepNhan: PhongBan;
  nguoiTiepNhan: User;
  /** @format date-time */
  thoiHanXuLy: string;
  noiDung: string;
  ghiChu: string;
  giayToDinhkem: GiayToCongVanDenDto[];
  trangThai: string;
}

export interface CongVanDen {
  id: number;
  soCongVanDen: string;
  /** @format date-time */
  ngayCongVanDen: string;
  nguoiGui: DoiTac;
  donViTiepNhan: PhongBan;
  nguoiTiepNhan: User;
  /** @format date-time */
  thoiHanXuLy: string;
  noiDung: string;
  ghiChu: string;
  giayToDinhKem: string[];
  trangThai: string;
}

export interface DanhMucThuChiDto {
  ten: string;
  loai: LoaiThuChi;
  stt: number;
}

export enum LoaiCauHoiEnum {
  TrắcNghiệm4Chọn1 = 'Trắc nghiệm 4 chọn 1',
  TrắcNghiệmDungSai = 'Trắc nghiệm đúng sai',
  ValueDiềnKhuyết = 'Điền khuyết',
  GhepCặpDung = 'Ghép cặp đúng',
  TựLuận = 'Tự luận',
}

export interface CauHoiDto {
  loaiCauHoi: LoaiCauHoiEnum;
  danhMuc1: DanhMucCauHoi;
  danhMuc2: DanhMucCauHoi;
  tenCauHoi: string;
  noiDungCauHoi: string;
  dapAn41: DapAn41[];
  dapAnTracNghiem: DapAnTracNghiem;
  dapAnDienKhuyet: DapAnDienKhuyet[];
  dapAnTuLuan: DapAnTuLuan;
  dapAnGhepCap: DapAnGhepCap[];
  cauHoiGhepCap: CauHoiGhepCap[];
  user?: User;
}

export interface QuanLyBoDeDto {
  danhMucBoDe: BoDe;
  tenBoDe: string;
  soCauHoi?: number;
  thoiGian?: number;
  thangDiem?: number;
  cauHoi: CauHoi[];
  user?: User;
}

export interface TaiLieuHocTapDto {
  loaiTaiLieu: string;
  tenTaiLieu: string;
  dinhKem: FileEntity;
  tenVideo: string;
  linkVideo: string;
  gioTruyCapToiThieu: number;
  phanTramDat: number;
  /** @format date-time */
  ngayGioHienThi: string;
  /** @format date-time */
  ngayGioDong: string;
  moTaNgan: string;
  moTa: string;
  hienThi: boolean;
  stt: number;
}

export interface TietHocOnlineDto {
  khoaHoc: KhoaHoc;
  hinhThucHoc: string;
  room: string;
  matKhau?: string;
  /** @format date-time */
  ngayTrucTuyen?: string;
}

export interface QuanLyTaiLieuDto {
  danhMucTaiLieu: TaiLieu;
  tenTaiLieu: string;
  tenTacGia?: string;
  fileDinhKem: FileEntity;
}

export interface QuanLyTaiLieuHocTap {
  id: number;
  danhMucTaiLieu: TaiLieu;
  tenTaiLieu: string;
  tacGia: string;
  fileDinhKem: FileEntity;
  user: User;
}

export interface BoPhanBanHanhDto {
  stt: number;
  ten: string;
}

export interface LoaiCongVanDiDto {
  stt: number;
  ten: string;
  soHieu: string;
  noiDung: string;
}

export interface AboutDto {
  tieuDe: string;
  noiDung: string;
  hinhAnh: FileEntity;
}

export interface About {
  id: number;
  tieuDe: string;
  noiDung: string;
  hinhAnh: FileEntity;
}

export interface ThongTinDto {
  noiDung: string;
}

export interface ThongTin {
  id: number;
  noiDung: string;
}

export interface SupportDto {
  hoTen: string;
  email: string;
  tieuDe: string;
  noiDung: string;
}

export interface Support {
  id: number;
  hoTen: string;
  email: string;
  tieuDe: string;
  noiDung: string;
}

export interface FAQDto {
  tieuDe: string;
  noiDung: string;
}

export interface FAQ {
  id: number;
  tieuDe: string;
  noiDung: string;
}

export interface CauHoiHocTap {
  id: number;
  cauHoi: string;
  traLoi: string;
  khoaHoc: KhoaHoc;
  nguoiGui: User;
  nguoiTraLoi: User;
  ngayGui: string;
  ngayTraLoi: string;
}

export interface CauHoiHocTapDto {
  cauHoi: string;
  traLoi: string;
}

export interface ViTriQuangCao {
  id: number;
  name: string;
}

export interface QuangCaoDto {
  name: string;
  stt: number;
  /** @format date-time */
  hetHan: string;
  viTri: ViTriQuangCao[];
  hinhAnh: FileEntity;
  link?: string;
}

export interface QuangCao {
  id: number;
  name: string;
  stt: number;
  /** @format date-time */
  hetHan: string;
  viTri: ViTriQuangCao[];
  hinhAnh: FileEntity;
  link: string;
}

export interface ChuyenKhoaDto {
  ten: string;
  stt: number;
}

export interface DinhKemQuanLyHocPhiDto {
  _id: number;
  ten?: string;
  fileDinhKem?: FileEntity;
}

export interface QuanLyHocPhiDto {
  soPhieu: string;
  /** @format date-time */
  ngay: string;
  quyTien: QuyTien;
  soTien: number;
  khoaHoc: KhoaHoc;
  hocVien: HocVien;
  nhanVien: NhanVien;
  noiDung: string;
  ghiChu: string;
  giayToDinhKems: DinhKemQuanLyHocPhiDto[];
}

export interface QuanLyHocPhi {
  id: number;
  soPhieu: string;
  khoaHoc: KhoaHoc;
  hocVien: HocVien;
  /** @format date-time */
  ngay: string;
  quyTien: QuyTien;
  soTien: number;
  nhanVien: NhanVien;
  noiDung: string;
  ghiChu: string;
  giayToDinhKems: DinhKemQuanLyHocPhi[];
}

export interface DinhKemQuanLyHocPhi {
  id: number;
  _id: number;
  ten: string;
  fileDinhKem: FileEntity;
  phieuThu: QuanLyHocPhi;
}

export interface HoiTruongDto {
  ten: string;
  stt: number;
  diaDiem: DiaDiem;
}

export interface HoiTruong {
  id: number;
  ten: string;
  stt: number;
  diaDiemId: number;
}

export interface ChuToaDto {
  khoaHoc: KhoaHoc;
  hoiTruong: HoiTruong;
  hocVien: HocVien;
  /** @format date-time */
  thoiGianTu: string;
  /** @format date-time */
  thoiGianDen: string;
  /** @format date-time */
  ngay: string;
  nhom: string;
  chucDanh: string;
}

export interface ChuToa {
  id: number;
  khoaHoc: KhoaHoc;
  hoiTruong: HoiTruong;
  hocVien: HocVien;
  /** @format date-time */
  thoiGianTu: string;
  /** @format date-time */
  thoiGianDen: string;
  /** @format date-time */
  ngay: string;
  nhom: string;
  chucDanh: string;
}

export interface DashboardDto {
  hocVien: number;
  giangVien: number;
  khoaHoc: number;
  chungChi: number;
  boDe: number;
  cauHoi: number;
  congVanDi: number;
  congVanDen: number;
  khoaHocChart: object;
  thanhVienOnMonth: object;
  thuChi: object;
  voucherOnMonth: object;
}

import axios, { AxiosInstance, AxiosRequestConfig, HeadersDefaults, ResponseType } from 'axios';

export type QueryParamsType = Record<string | number, any>;

export interface FullRequestParams extends Omit<AxiosRequestConfig, 'data' | 'params' | 'url' | 'responseType'> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean;
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseType;
  /** request body */
  body?: unknown;
}

export type RequestParams = Omit<FullRequestParams, 'body' | 'method' | 'query' | 'path'>;

export interface ApiConfig<SecurityDataType = unknown> extends Omit<AxiosRequestConfig, 'data' | 'cancelToken'> {
  securityWorker?: (securityData: SecurityDataType | null) => Promise<AxiosRequestConfig | void> | AxiosRequestConfig | void;
  secure?: boolean;
  format?: ResponseType;
}

export enum ContentType {
  Json = 'application/json',
  FormData = 'multipart/form-data',
  UrlEncoded = 'application/x-www-form-urlencoded',
  Text = 'text/plain',
}

export class HttpClient<SecurityDataType = unknown> {
  public instance: AxiosInstance;
  private securityData: SecurityDataType | null = null;
  private securityWorker?: ApiConfig<SecurityDataType>['securityWorker'];
  private secure?: boolean;
  private format?: ResponseType;

  constructor({ securityWorker, secure, format, ...axiosConfig }: ApiConfig<SecurityDataType> = {}) {
    this.instance = axios.create({ ...axiosConfig, baseURL: axiosConfig.baseURL || '' });
    this.secure = secure;
    this.format = format;
    this.securityWorker = securityWorker;
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data;
  };

  protected mergeRequestParams(params1: AxiosRequestConfig, params2?: AxiosRequestConfig): AxiosRequestConfig {
    const method = params1.method || (params2 && params2.method);

    return {
      ...this.instance.defaults,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...((method && this.instance.defaults.headers[method.toLowerCase() as keyof HeadersDefaults]) || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    };
  }

  protected stringifyFormItem(formItem: unknown) {
    if (typeof formItem === 'object' && formItem !== null) {
      return JSON.stringify(formItem);
    } else {
      return `${formItem}`;
    }
  }

  protected createFormData(input: Record<string, unknown>): FormData {
    return Object.keys(input || {}).reduce((formData, key) => {
      const property = input[key];
      const propertyContent: any[] = property instanceof Array ? property : [property];

      for (const formItem of propertyContent) {
        const isFileType = formItem instanceof Blob || formItem instanceof File;
        formData.append(key, isFileType ? formItem : this.stringifyFormItem(formItem));
      }

      return formData;
    }, new FormData());
  }

  public request = async <T = any, _E = any>({ secure, path, type, query, format, body, ...params }: FullRequestParams): Promise<T> => {
    const secureParams =
      ((typeof secure === 'boolean' ? secure : this.secure) && this.securityWorker && (await this.securityWorker(this.securityData))) || {};
    const requestParams = this.mergeRequestParams(params, secureParams);
    const responseFormat = format || this.format || undefined;

    if (type === ContentType.FormData && body && body !== null && typeof body === 'object') {
      body = this.createFormData(body as Record<string, unknown>);
    }

    if (type === ContentType.Text && body && body !== null && typeof body !== 'string') {
      body = JSON.stringify(body);
    }

    return this.instance
      .request({
        ...requestParams,
        headers: {
          ...(requestParams.headers || {}),
          ...(type && type !== ContentType.FormData ? { 'Content-Type': type } : {}),
        },
        params: query,
        responseType: responseFormat,
        data: body,
        url: path,
      })
      .then((response) => response?.data);
  };
}

/**
 * @title API
 * @version 1.0
 * @contact
 *
 * API docs
 */
export class Api<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
  api = {
    /**
     * No description
     *
     * @name UsersControllerInfo
     * @request GET:/api/users/info
     */
    usersInfo: (params: RequestParams = {}) =>
      this.request<User, any>({
        path: `/api/users/info`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name UsersControllerUpdate
     * @request PATCH:/api/users/{id}
     */
    usersUpdate: (id: number, data: PartialType, params: RequestParams = {}) =>
      this.request<User, any>({
        path: `/api/users/${id}`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name UsersControllerFindById
     * @request GET:/api/users/{id}
     */
    usersFindById: (id: string, params: RequestParams = {}) =>
      this.request<User, any>({
        path: `/api/users/${id}`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name UsersControllerFind
     * @request GET:/api/users
     */
    usersFind: (params: RequestParams = {}) =>
      this.request<User[], any>({
        path: `/api/users`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags permission
     * @name PermissionControllerFind
     * @request GET:/api/permission
     */
    permissionFind: (params: RequestParams = {}) =>
      this.request<PermissionEntity[], any>({
        path: `/api/permission`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags permission
     * @name PermissionControllerFindById
     * @request GET:/api/permission/{id}
     */
    permissionFindById: (id: string, params: RequestParams = {}) =>
      this.request<PermissionEntity, any>({
        path: `/api/permission/${id}`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name RolesControllerCreate
     * @request POST:/api/roles
     */
    rolesCreate: (data: RoleDto, params: RequestParams = {}) =>
      this.request<Role, any>({
        path: `/api/roles`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name RolesControllerFind
     * @request GET:/api/roles
     */
    rolesFind: (params: RequestParams = {}) =>
      this.request<Role[], any>({
        path: `/api/roles`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name RolesControllerFindById
     * @request GET:/api/roles/{id}
     */
    rolesFindById: (id: string, params: RequestParams = {}) =>
      this.request<Role, any>({
        path: `/api/roles/${id}`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name RolesControllerUpdate
     * @request PATCH:/api/roles/{id}
     */
    rolesUpdate: (id: string, data: RoleDto, params: RequestParams = {}) =>
      this.request<Role, any>({
        path: `/api/roles/${id}`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name RolesControllerSoftDelete
     * @request DELETE:/api/roles/{id}
     */
    rolesSoftDelete: (id: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/roles/${id}`,
        method: 'DELETE',
        ...params,
      }),

    /**
     * No description
     *
     * @name HocVienControllerEnrollCourseForNewHocVien
     * @request POST:/api/hoc-vien/new/enroll-course
     */
    hocVienEnrollCourseForNewHocVien: (data: EnrollKhoaHocDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/hoc-vien/new/enroll-course`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @name HocVienControllerEnrollCourseForOldHocVien
     * @request POST:/api/hoc-vien/old/enroll-course
     */
    hocVienEnrollCourseForOldHocVien: (
      query: {
        khoaHocId: number;
        maVoucher: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/api/hoc-vien/old/enroll-course`,
        method: 'POST',
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @name HocVienControllerGetInfo
     * @request GET:/api/hoc-vien/info
     */
    hocVienGetInfo: (params: RequestParams = {}) =>
      this.request<HocVien, any>({
        path: `/api/hoc-vien/info`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name HocVienControllerGetKhoaHocCuaToi
     * @request GET:/api/hoc-vien/khoa-hoc
     */
    hocVienGetKhoaHocCuaToi: (
      query: {
        loaiHinhDaoTaoId: string;
        danhMucDaoTaoId: string;
        tenGiangVien: string;
        tenKhoaHoc: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<KhoaHoc[], any>({
        path: `/api/hoc-vien/khoa-hoc`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name HocVienControllerCreate
     * @request POST:/api/hoc-vien
     */
    hocVienCreate: (data: HocVienDto, params: RequestParams = {}) =>
      this.request<HocVien, any>({
        path: `/api/hoc-vien`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name HocVienControllerFind
     * @request GET:/api/hoc-vien
     */
    hocVienFind: (params: RequestParams = {}) =>
      this.request<HocVien[], any>({
        path: `/api/hoc-vien`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name HocVienControllerFindById
     * @request GET:/api/hoc-vien/{id}
     */
    hocVienFindById: (id: string, params: RequestParams = {}) =>
      this.request<HocVien, any>({
        path: `/api/hoc-vien/${id}`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name HocVienControllerUpdate
     * @request PATCH:/api/hoc-vien/{id}
     */
    hocVienUpdate: (id: string, data: HocVienDto, params: RequestParams = {}) =>
      this.request<HocVien, any>({
        path: `/api/hoc-vien/${id}`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name HocVienControllerSoftDelete
     * @request DELETE:/api/hoc-vien/{id}
     */
    hocVienSoftDelete: (id: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/hoc-vien/${id}`,
        method: 'DELETE',
        ...params,
      }),

    /**
     * No description
     *
     * @name NhanVienControllerCreate
     * @request POST:/api/nhan-vien
     */
    nhanVienCreate: (data: NhanVienDto, params: RequestParams = {}) =>
      this.request<NhanVien, any>({
        path: `/api/nhan-vien`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name NhanVienControllerFind
     * @request GET:/api/nhan-vien
     */
    nhanVienFind: (params: RequestParams = {}) =>
      this.request<NhanVien[], any>({
        path: `/api/nhan-vien`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name NhanVienControllerFindById
     * @request GET:/api/nhan-vien/{id}
     */
    nhanVienFindById: (id: string, params: RequestParams = {}) =>
      this.request<NhanVien, any>({
        path: `/api/nhan-vien/${id}`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name NhanVienControllerUpdate
     * @request PATCH:/api/nhan-vien/{id}
     */
    nhanVienUpdate: (id: string, data: NhanVienDto, params: RequestParams = {}) =>
      this.request<NhanVien, any>({
        path: `/api/nhan-vien/${id}`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name NhanVienControllerSoftDelete
     * @request DELETE:/api/nhan-vien/{id}
     */
    nhanVienSoftDelete: (id: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/nhan-vien/${id}`,
        method: 'DELETE',
        ...params,
      }),

    /**
     * No description
     *
     * @name GiangVienControllerCreate
     * @request POST:/api/giang-vien
     */
    giangVienCreate: (data: GiangVienDto, params: RequestParams = {}) =>
      this.request<GiangVien, any>({
        path: `/api/giang-vien`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name GiangVienControllerFind
     * @request GET:/api/giang-vien
     */
    giangVienFind: (params: RequestParams = {}) =>
      this.request<GiangVien[], any>({
        path: `/api/giang-vien`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name GiangVienControllerFindById
     * @request GET:/api/giang-vien/{id}
     */
    giangVienFindById: (id: string, params: RequestParams = {}) =>
      this.request<GiangVien, any>({
        path: `/api/giang-vien/${id}`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name GiangVienControllerUpdate
     * @request PATCH:/api/giang-vien/{id}
     */
    giangVienUpdate: (id: string, data: GiangVienDto, params: RequestParams = {}) =>
      this.request<GiangVien, any>({
        path: `/api/giang-vien/${id}`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name GiangVienControllerSoftDelete
     * @request DELETE:/api/giang-vien/{id}
     */
    giangVienSoftDelete: (id: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/giang-vien/${id}`,
        method: 'DELETE',
        ...params,
      }),

    /**
     * No description
     *
     * @name VoucherControllerGetVouchers
     * @request GET:/api/voucher
     */
    voucherGetVouchers: (
      query: {
        tinhTrang: TinhTrangVoucherEnum;
        ma: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<Voucher[], any>({
        path: `/api/voucher`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name VoucherControllerCreateVoucher
     * @request POST:/api/voucher
     */
    voucherCreateVoucher: (data: VoucherDto, params: RequestParams = {}) =>
      this.request<Voucher, any>({
        path: `/api/voucher`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name VoucherControllerGetVoucherCaNhanCuaToi
     * @request GET:/api/voucher/ca-nhan/owner
     */
    voucherGetVoucherCaNhanCuaToi: (params: RequestParams = {}) =>
      this.request<Voucher, any>({
        path: `/api/voucher/ca-nhan/owner`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name VoucherControllerGetVoucherOpenForUser
     * @request GET:/api/voucher/dang-mo
     */
    voucherGetVoucherOpenForUser: (
      query: {
        tinhTrang: TinhTrangVoucherEnum;
        ma: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<Voucher[], any>({
        path: `/api/voucher/dang-mo`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name VoucherControllerThongKeChiTraVoucher
     * @request GET:/api/voucher/thong-ke-chi-tra
     */
    voucherThongKeChiTraVoucher: (
      query: {
        nam: number;
        thang: number;
        userId: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<KhoaHocHocVien[], any>({
        path: `/api/voucher/thong-ke-chi-tra`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name VoucherControllerGetSubVoucherApDungsByMaVoucher
     * @request GET:/api/voucher/ap-dung/{ma}
     */
    voucherGetSubVoucherApDungsByMaVoucher: (ma: string, params: RequestParams = {}) =>
      this.request<VoucherApDung[], any>({
        path: `/api/voucher/ap-dung/${ma}`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name VoucherControllerThongKeVoucher
     * @request GET:/api/voucher/thong-ke
     */
    voucherThongKeVoucher: (
      query: {
        nam: number;
        thang: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<VoucherThongKe[], any>({
        path: `/api/voucher/thong-ke`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name VoucherControllerThongKeSubVoucher
     * @request GET:/api/voucher/sub-voucher/thong-ke
     */
    voucherThongKeSubVoucher: (
      query: {
        tuNgay: string;
        denNgay: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<SubVoucherThongKe[], any>({
        path: `/api/voucher/sub-voucher/thong-ke`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name VoucherControllerGetDiemThuongThanhToan
     * @request GET:/api/voucher/sub-voucher/diem-thuong-thanh-toan
     */
    voucherGetDiemThuongThanhToan: (params: RequestParams = {}) =>
      this.request<DiemThuongThanhToan[], any>({
        path: `/api/voucher/sub-voucher/diem-thuong-thanh-toan`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name VoucherControllerThanhToanVoucher
     * @request POST:/api/voucher/thanh-toan
     */
    voucherThanhToanVoucher: (data: ThanhToanVoucherDto, params: RequestParams = {}) =>
      this.request<ThanhToanVoucher, any>({
        path: `/api/voucher/thanh-toan`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name VoucherControllerGetThanhToan
     * @request GET:/api/voucher/thanh-toan
     */
    voucherGetThanhToan: (
      query: {
        nam: number;
        thang: number;
        userId: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<ThanhToanVoucher, any>({
        path: `/api/voucher/thanh-toan`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name VoucherControllerChiDuThanhToan
     * @request POST:/api/voucher/thanh-toan/chi-du
     */
    voucherChiDuThanhToan: (
      query: {
        nam: number;
        thang: number;
        userId: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<ThanhToanVoucher, any>({
        path: `/api/voucher/thanh-toan/chi-du`,
        method: 'POST',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name VoucherControllerCreateSubVoucher
     * @request POST:/api/voucher/sub-voucher
     */
    voucherCreateSubVoucher: (data: SubVoucherDto, params: RequestParams = {}) =>
      this.request<SubVoucher, any>({
        path: `/api/voucher/sub-voucher`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name VoucherControllerUpdateVoucher
     * @request PATCH:/api/voucher/{ma}
     */
    voucherUpdateVoucher: (ma: string, data: VoucherDto, params: RequestParams = {}) =>
      this.request<Voucher, any>({
        path: `/api/voucher/${ma}`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name VoucherControllerGetVoucherByMa
     * @request GET:/api/voucher/{ma}
     */
    voucherGetVoucherByMa: (ma: string, params: RequestParams = {}) =>
      this.request<Voucher, any>({
        path: `/api/voucher/${ma}`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name VoucherControllerDeleteVoucherApDung
     * @request DELETE:/api/voucher/ap-dung/{id}
     */
    voucherDeleteVoucherApDung: (id: number, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/voucher/ap-dung/${id}`,
        method: 'DELETE',
        ...params,
      }),

    /**
     * No description
     *
     * @name VoucherControllerPublicGetVoucherByMa
     * @request GET:/api/voucher/public/{ma}
     */
    voucherPublicGetVoucherByMa: (ma: string, params: RequestParams = {}) =>
      this.request<Voucher, any>({
        path: `/api/voucher/public/${ma}`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name KhoaHocControllerGetPublic
     * @request GET:/api/khoa-hoc/public
     */
    khoaHocGetPublic: (params: RequestParams = {}) =>
      this.request<KhoaHoc[], any>({
        path: `/api/khoa-hoc/public`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name KhoaHocControllerGetChuongTrinhHoc
     * @request GET:/api/khoa-hoc/chuong-trinh-hoc/{id}
     */
    khoaHocGetChuongTrinhHoc: (id: number, params: RequestParams = {}) =>
      this.request<KhoaHoc, any>({
        path: `/api/khoa-hoc/chuong-trinh-hoc/${id}`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name KhoaHocControllerGetHocVienGhiDanh
     * @request GET:/api/khoa-hoc/hoc-vien-ghi-danh
     */
    khoaHocGetHocVienGhiDanh: (
      query: {
        loaiHinhDaoTaoId: number;
        danhMucDaoTaoId: number;
        tenKhoaHoc: string;
        tuNgay: string;
        denNgay: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<KhoaHocHocVien[], any>({
        path: `/api/khoa-hoc/hoc-vien-ghi-danh`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name KhoaHocControllerGetHocVienThamGia
     * @request GET:/api/khoa-hoc/hoc-vien-tham-gia/{id}
     */
    khoaHocGetHocVienThamGia: (id: number, params: RequestParams = {}) =>
      this.request<KhoaHocHocVien[], any>({
        path: `/api/khoa-hoc/hoc-vien-tham-gia/${id}`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name KhoaHocControllerGetTongDiemHocVien
     * @request GET:/api/khoa-hoc/tong-diem-cuoi-ky-hoc-vien/{id}
     */
    khoaHocGetTongDiemHocVien: (id: number, params: RequestParams = {}) =>
      this.request<QuanLyKiemTra[], any>({
        path: `/api/khoa-hoc/tong-diem-cuoi-ky-hoc-vien/${id}`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name KhoaHocControllerCheckHocVienGhiDanh
     * @request GET:/api/khoa-hoc/da-ghi-danh/{khoaHocId}/{hocVienId}
     */
    khoaHocCheckHocVienGhiDanh: (
      khoaHocId: number,
      hocVienId: number,
      query: {
        loaiHinhDaoTaoId: number;
        danhMucDaoTaoId: number;
        tenKhoaHoc: string;
        tuNgay: string;
        denNgay: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<KhoaHocHocVien[], any>({
        path: `/api/khoa-hoc/da-ghi-danh/${khoaHocId}/${hocVienId}`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name KhoaHocControllerAddHocVienGhiDanh
     * @request PUT:/api/khoa-hoc/hoc-vien-ghi-danh/ghi-danh/{id}
     */
    khoaHocAddHocVienGhiDanh: (
      id: number,
      query: {
        maVoucher: string;
        hocVienId: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<KhoaHocHocVien, any>({
        path: `/api/khoa-hoc/hoc-vien-ghi-danh/ghi-danh/${id}`,
        method: 'PUT',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name KhoaHocControllerGetHocVienGhiDanhById
     * @request GET:/api/khoa-hoc/hoc-vien-ghi-danh/{id}
     */
    khoaHocGetHocVienGhiDanhById: (id: number, params: RequestParams = {}) =>
      this.request<KhoaHocHocVien, any>({
        path: `/api/khoa-hoc/hoc-vien-ghi-danh/${id}`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name KhoaHocControllerUpdateHocVienGhiDanhById
     * @request PUT:/api/khoa-hoc/hoc-vien-ghi-danh/{id}
     */
    khoaHocUpdateHocVienGhiDanhById: (id: number, data: HocVienGhiDanhDto, params: RequestParams = {}) =>
      this.request<KhoaHocHocVien, any>({
        path: `/api/khoa-hoc/hoc-vien-ghi-danh/${id}`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name KhoaHocControllerDeleteGhiDanh
     * @request DELETE:/api/khoa-hoc/hoc-vien-ghi-danh/{id}
     */
    khoaHocDeleteGhiDanh: (id: number, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/khoa-hoc/hoc-vien-ghi-danh/${id}`,
        method: 'DELETE',
        ...params,
      }),

    /**
     * No description
     *
     * @name KhoaHocControllerDuyetGhiDanh
     * @request PUT:/api/khoa-hoc/hoc-vien-ghi-danh/duyet/{id}
     */
    khoaHocDuyetGhiDanh: (id: number, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/khoa-hoc/hoc-vien-ghi-danh/duyet/${id}`,
        method: 'PUT',
        ...params,
      }),

    /**
     * No description
     *
     * @name KhoaHocControllerTuChoiGhiDanh
     * @request PUT:/api/khoa-hoc/hoc-vien-ghi-danh/tu-choi/{id}
     */
    khoaHocTuChoiGhiDanh: (id: number, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/khoa-hoc/hoc-vien-ghi-danh/tu-choi/${id}`,
        method: 'PUT',
        ...params,
      }),

    /**
     * No description
     *
     * @name KhoaHocControllerDetail
     * @request GET:/api/khoa-hoc/public/{id}
     */
    khoaHocDetail: (id: string, params: RequestParams = {}) =>
      this.request<KhoaHoc, any>({
        path: `/api/khoa-hoc/public/${id}`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name KhoaHocControllerLamLaiKiemTra
     * @request GET:/api/khoa-hoc/kiem-tra/thi-lai
     */
    khoaHocLamLaiKiemTra: (
      query: {
        kiemTraId: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<QuanLyKiemTra, any>({
        path: `/api/khoa-hoc/kiem-tra/thi-lai`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name KhoaHocControllerGetBaiKiemTraByHocVien
     * @request GET:/api/khoa-hoc/kiem-tra/hoc-vien/{id}
     */
    khoaHocGetBaiKiemTraByHocVien: (
      id: number,
      query: {
        boDeThiId: number;
        hocVienId: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<QuanLyKiemTra, any>({
        path: `/api/khoa-hoc/kiem-tra/hoc-vien/${id}`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name KhoaHocControllerLamBaiKiemTra
     * @request GET:/api/khoa-hoc/kiem-tra/{id}
     */
    khoaHocLamBaiKiemTra: (
      id: number,
      query: {
        boDeThiId: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<QuanLyKiemTra, any>({
        path: `/api/khoa-hoc/kiem-tra/${id}`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name KhoaHocControllerCloneKhoaHoc
     * @request POST:/api/khoa-hoc/clone/{id}
     */
    khoaHocCloneKhoaHoc: (
      id: number,
      query: {
        tenKhoaHoc: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<QuanLyKiemTra, any>({
        path: `/api/khoa-hoc/clone/${id}`,
        method: 'POST',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name KhoaHocControllerUpdateKiemTra
     * @request POST:/api/khoa-hoc/quan-ly-kiem-tra
     */
    khoaHocUpdateKiemTra: (
      query: {
        quanLyKiemTraId: number;
      },
      data: QuanLyKiemTraDto,
      params: RequestParams = {},
    ) =>
      this.request<QuanLyKiemTra, any>({
        path: `/api/khoa-hoc/quan-ly-kiem-tra`,
        method: 'POST',
        query: query,
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name KhoaHocControllerNopBaiKiemTra
     * @request POST:/api/khoa-hoc/cham-diem-kiem-tra
     */
    khoaHocNopBaiKiemTra: (
      query: {
        quanLyKiemTraId: number;
      },
      data: QuanLyKiemTraDto,
      params: RequestParams = {},
    ) =>
      this.request<QuanLyKiemTra, any>({
        path: `/api/khoa-hoc/cham-diem-kiem-tra`,
        method: 'POST',
        query: query,
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name KhoaHocControllerGiangVienChamDiemKiemTra
     * @request POST:/api/khoa-hoc/giang-vien/cham-diem-kiem-tra
     */
    khoaHocGiangVienChamDiemKiemTra: (
      query: {
        quanLyKiemTraId: number;
      },
      data: QuanLyKiemTraDto,
      params: RequestParams = {},
    ) =>
      this.request<QuanLyKiemTra, any>({
        path: `/api/khoa-hoc/giang-vien/cham-diem-kiem-tra`,
        method: 'POST',
        query: query,
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name KhoaHocControllerCapChungNhan
     * @request PUT:/api/khoa-hoc/cap-chung-nhan/{id}
     */
    khoaHocCapChungNhan: (
      id: number,
      query: {
        hocVienId: number;
        capChungNhan: boolean;
      },
      params: RequestParams = {},
    ) =>
      this.request<KhoaHocHocVien, any>({
        path: `/api/khoa-hoc/cap-chung-nhan/${id}`,
        method: 'PUT',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name KhoaHocControllerGuiMail
     * @request POST:/api/khoa-hoc/mail/{id}
     */
    khoaHocGuiMail: (id: number, data: MailKhoaHocDto, params: RequestParams = {}) =>
      this.request<KhoaHoc, any>({
        path: `/api/khoa-hoc/mail/${id}`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name KhoaHocControllerGetTienDoHocTap
     * @request GET:/api/khoa-hoc/tien-do-hoc-tap/{id}
     */
    khoaHocGetTienDoHocTap: (id: number, params: RequestParams = {}) =>
      this.request<TienDoHocTap[], any>({
        path: `/api/khoa-hoc/tien-do-hoc-tap/${id}`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name KhoaHocControllerGetTienDoHocTapByHocVienId
     * @request GET:/api/khoa-hoc/tien-do-hoc-tap/hoc-vien/{id}
     */
    khoaHocGetTienDoHocTapByHocVienId: (
      id: number,
      query: {
        hocVienId: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<TienDoHocTap[], any>({
        path: `/api/khoa-hoc/tien-do-hoc-tap/hoc-vien/${id}`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name KhoaHocControllerGetChungNhan
     * @request GET:/api/khoa-hoc/chung-nhan/{id}
     */
    khoaHocGetChungNhan: (id: number, params: RequestParams = {}) =>
      this.request<KhoaHocHocVien, any>({
        path: `/api/khoa-hoc/chung-nhan/${id}`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name KhoaHocControllerGetChungNhanPublic
     * @request GET:/api/khoa-hoc/chung-nhan/{soChungNhan}/public
     */
    khoaHocGetChungNhanPublic: (soChungNhan: number, params: RequestParams = {}) =>
      this.request<KhoaHocHocVien, any>({
        path: `/api/khoa-hoc/chung-nhan/${soChungNhan}/public`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name KhoaHocControllerGetChungNhanByHocVienId
     * @request GET:/api/khoa-hoc/chung-nhan/hoc-vien/{id}
     */
    khoaHocGetChungNhanByHocVienId: (
      id: number,
      query: {
        hocVienId: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<KhoaHocHocVien, any>({
        path: `/api/khoa-hoc/chung-nhan/hoc-vien/${id}`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name KhoaHocControllerXacNhanThanhToan
     * @request POST:/api/khoa-hoc/xac-nhan-thanh-toan/{id}
     */
    khoaHocXacNhanThanhToan: (id: number, data: XacNhanThanhToanDto, params: RequestParams = {}) =>
      this.request<KhoaHocHocVien, any>({
        path: `/api/khoa-hoc/xac-nhan-thanh-toan/${id}`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name KhoaHocControllerCreate
     * @request POST:/api/khoa-hoc
     */
    khoaHocCreate: (data: KhoaHocDto, params: RequestParams = {}) =>
      this.request<KhoaHoc, any>({
        path: `/api/khoa-hoc`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name KhoaHocControllerFind
     * @request GET:/api/khoa-hoc
     */
    khoaHocFind: (params: RequestParams = {}) =>
      this.request<KhoaHoc[], any>({
        path: `/api/khoa-hoc`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name KhoaHocControllerFindById
     * @request GET:/api/khoa-hoc/{id}
     */
    khoaHocFindById: (id: string, params: RequestParams = {}) =>
      this.request<KhoaHoc, any>({
        path: `/api/khoa-hoc/${id}`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name KhoaHocControllerUpdate
     * @request PATCH:/api/khoa-hoc/{id}
     */
    khoaHocUpdate: (id: string, data: KhoaHocDto, params: RequestParams = {}) =>
      this.request<KhoaHoc, any>({
        path: `/api/khoa-hoc/${id}`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name KhoaHocControllerSoftDelete
     * @request DELETE:/api/khoa-hoc/{id}
     */
    khoaHocSoftDelete: (id: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/khoa-hoc/${id}`,
        method: 'DELETE',
        ...params,
      }),

    /**
     * No description
     *
     * @name QuanLyBoDeThiControllerCreate
     * @request POST:/api/quan-ly-bo-de-thi
     */
    quanLyBoDeThiCreate: (data: QuanLyBoDeThiDto, params: RequestParams = {}) =>
      this.request<QuanLyBoDeThi, any>({
        path: `/api/quan-ly-bo-de-thi`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name QuanLyBoDeThiControllerFind
     * @request GET:/api/quan-ly-bo-de-thi
     */
    quanLyBoDeThiFind: (params: RequestParams = {}) =>
      this.request<QuanLyBoDeThi[], any>({
        path: `/api/quan-ly-bo-de-thi`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name QuanLyBoDeThiControllerFindById
     * @request GET:/api/quan-ly-bo-de-thi/{id}
     */
    quanLyBoDeThiFindById: (id: string, params: RequestParams = {}) =>
      this.request<QuanLyBoDeThi, any>({
        path: `/api/quan-ly-bo-de-thi/${id}`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name QuanLyBoDeThiControllerUpdate
     * @request PATCH:/api/quan-ly-bo-de-thi/{id}
     */
    quanLyBoDeThiUpdate: (id: string, data: QuanLyBoDeThiDto, params: RequestParams = {}) =>
      this.request<QuanLyBoDeThi, any>({
        path: `/api/quan-ly-bo-de-thi/${id}`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name QuanLyBoDeThiControllerSoftDelete
     * @request DELETE:/api/quan-ly-bo-de-thi/{id}
     */
    quanLyBoDeThiSoftDelete: (id: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/quan-ly-bo-de-thi/${id}`,
        method: 'DELETE',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Files
     * @name FilesControllerUploadFile
     * @request POST:/api/files/upload
     * @secure
     */
    filesUploadFile: (
      data: {
        /** @format binary */
        file?: File;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/api/files/upload`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.FormData,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Files
     * @name FilesControllerDownload
     * @request GET:/api/files/{path}
     */
    filesDownload: (path: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/files/${path}`,
        method: 'GET',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Auth
     * @name AuthControllerLogin
     * @request POST:/api/auth/login
     */
    authLogin: (data: AuthUsernameLoginDto, params: RequestParams = {}) =>
      this.request<LoginResponse, any>({
        path: `/api/auth/login`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Auth
     * @name AuthControllerGoogleLogin
     * @request POST:/api/auth/google/login
     */
    authGoogleLogin: (data: AuthGoogleLoginDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/auth/google/login`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Auth
     * @name AuthControllerMe
     * @request GET:/api/auth/me
     * @secure
     */
    authMe: (params: RequestParams = {}) =>
      this.request<User, any>({
        path: `/api/auth/me`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Auth
     * @name AuthControllerRefresh
     * @request POST:/api/auth/refresh
     * @secure
     */
    authRefresh: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/auth/refresh`,
        method: 'POST',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Auth
     * @name AuthControllerLogout
     * @request POST:/api/auth/logout
     * @secure
     */
    authLogout: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/auth/logout`,
        method: 'POST',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Auth
     * @name AuthControllerRegister
     * @request POST:/api/auth/email/register
     */
    authRegister: (data: AuthRegisterLoginDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/auth/email/register`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Auth
     * @name AuthControllerForgotPassword
     * @request POST:/api/auth/forgot/password
     */
    authForgotPassword: (data: AuthForgotPasswordDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/auth/forgot/password`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @name LoaiHinhDaoTaoControllerCreate
     * @request POST:/api/loai-hinh-dao-tao
     */
    loaiHinhDaoTaoCreate: (data: LoaiHinhDaoTaoDto, params: RequestParams = {}) =>
      this.request<LoaiHinhDaoTao, any>({
        path: `/api/loai-hinh-dao-tao`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name LoaiHinhDaoTaoControllerFind
     * @request GET:/api/loai-hinh-dao-tao
     */
    loaiHinhDaoTaoFind: (params: RequestParams = {}) =>
      this.request<LoaiHinhDaoTao[], any>({
        path: `/api/loai-hinh-dao-tao`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name LoaiHinhDaoTaoControllerFindById
     * @request GET:/api/loai-hinh-dao-tao/{id}
     */
    loaiHinhDaoTaoFindById: (id: string, params: RequestParams = {}) =>
      this.request<LoaiHinhDaoTao, any>({
        path: `/api/loai-hinh-dao-tao/${id}`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name LoaiHinhDaoTaoControllerUpdate
     * @request PATCH:/api/loai-hinh-dao-tao/{id}
     */
    loaiHinhDaoTaoUpdate: (id: string, data: LoaiHinhDaoTaoDto, params: RequestParams = {}) =>
      this.request<LoaiHinhDaoTao, any>({
        path: `/api/loai-hinh-dao-tao/${id}`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name LoaiHinhDaoTaoControllerSoftDelete
     * @request DELETE:/api/loai-hinh-dao-tao/{id}
     */
    loaiHinhDaoTaoSoftDelete: (id: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/loai-hinh-dao-tao/${id}`,
        method: 'DELETE',
        ...params,
      }),

    /**
     * No description
     *
     * @name DanhMucDaoTaoControllerCreate
     * @request POST:/api/danh-muc-dao-tao
     */
    danhMucDaoTaoCreate: (data: DanhMucDaoTaoDto, params: RequestParams = {}) =>
      this.request<DanhMucDaoTao, any>({
        path: `/api/danh-muc-dao-tao`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name DanhMucDaoTaoControllerFind
     * @request GET:/api/danh-muc-dao-tao
     */
    danhMucDaoTaoFind: (params: RequestParams = {}) =>
      this.request<DanhMucDaoTao[], any>({
        path: `/api/danh-muc-dao-tao`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name DanhMucDaoTaoControllerFindById
     * @request GET:/api/danh-muc-dao-tao/{id}
     */
    danhMucDaoTaoFindById: (id: string, params: RequestParams = {}) =>
      this.request<DanhMucDaoTao, any>({
        path: `/api/danh-muc-dao-tao/${id}`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name DanhMucDaoTaoControllerUpdate
     * @request PATCH:/api/danh-muc-dao-tao/{id}
     */
    danhMucDaoTaoUpdate: (id: string, data: DanhMucDaoTaoDto, params: RequestParams = {}) =>
      this.request<DanhMucDaoTao, any>({
        path: `/api/danh-muc-dao-tao/${id}`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name DanhMucDaoTaoControllerSoftDelete
     * @request DELETE:/api/danh-muc-dao-tao/{id}
     */
    danhMucDaoTaoSoftDelete: (id: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/danh-muc-dao-tao/${id}`,
        method: 'DELETE',
        ...params,
      }),

    /**
     * No description
     *
     * @name DiaDiemControllerCreate
     * @request POST:/api/dia-diem
     */
    diaDiemCreate: (data: DiaDiemDto, params: RequestParams = {}) =>
      this.request<DiaDiem, any>({
        path: `/api/dia-diem`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name DiaDiemControllerFind
     * @request GET:/api/dia-diem
     */
    diaDiemFind: (params: RequestParams = {}) =>
      this.request<DiaDiem[], any>({
        path: `/api/dia-diem`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name DiaDiemControllerFindById
     * @request GET:/api/dia-diem/{id}
     */
    diaDiemFindById: (id: string, params: RequestParams = {}) =>
      this.request<DiaDiem, any>({
        path: `/api/dia-diem/${id}`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name DiaDiemControllerUpdate
     * @request PATCH:/api/dia-diem/{id}
     */
    diaDiemUpdate: (id: string, data: DiaDiemDto, params: RequestParams = {}) =>
      this.request<DiaDiem, any>({
        path: `/api/dia-diem/${id}`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name DiaDiemControllerSoftDelete
     * @request DELETE:/api/dia-diem/{id}
     */
    diaDiemSoftDelete: (id: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/dia-diem/${id}`,
        method: 'DELETE',
        ...params,
      }),

    /**
     * No description
     *
     * @name ChungChiControllerCreate
     * @request POST:/api/chung-chi
     */
    chungChiCreate: (data: ChungChiDto, params: RequestParams = {}) =>
      this.request<ChungChi, any>({
        path: `/api/chung-chi`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name ChungChiControllerFind
     * @request GET:/api/chung-chi
     */
    chungChiFind: (params: RequestParams = {}) =>
      this.request<ChungChi[], any>({
        path: `/api/chung-chi`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name ChungChiControllerFindById
     * @request GET:/api/chung-chi/{id}
     */
    chungChiFindById: (id: string, params: RequestParams = {}) =>
      this.request<ChungChi, any>({
        path: `/api/chung-chi/${id}`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name ChungChiControllerUpdate
     * @request PATCH:/api/chung-chi/{id}
     */
    chungChiUpdate: (id: string, data: ChungChiDto, params: RequestParams = {}) =>
      this.request<ChungChi, any>({
        path: `/api/chung-chi/${id}`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name ChungChiControllerSoftDelete
     * @request DELETE:/api/chung-chi/{id}
     */
    chungChiSoftDelete: (id: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/chung-chi/${id}`,
        method: 'DELETE',
        ...params,
      }),

    /**
     * No description
     *
     * @name HocHamControllerCreate
     * @request POST:/api/hoc-ham
     */
    hocHamCreate: (data: HocHamDto, params: RequestParams = {}) =>
      this.request<HocHam, any>({
        path: `/api/hoc-ham`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name HocHamControllerFind
     * @request GET:/api/hoc-ham
     */
    hocHamFind: (params: RequestParams = {}) =>
      this.request<HocHam[], any>({
        path: `/api/hoc-ham`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name HocHamControllerFindById
     * @request GET:/api/hoc-ham/{id}
     */
    hocHamFindById: (id: string, params: RequestParams = {}) =>
      this.request<HocHam, any>({
        path: `/api/hoc-ham/${id}`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name HocHamControllerUpdate
     * @request PATCH:/api/hoc-ham/{id}
     */
    hocHamUpdate: (id: string, data: HocHamDto, params: RequestParams = {}) =>
      this.request<HocHam, any>({
        path: `/api/hoc-ham/${id}`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name HocHamControllerSoftDelete
     * @request DELETE:/api/hoc-ham/{id}
     */
    hocHamSoftDelete: (id: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/hoc-ham/${id}`,
        method: 'DELETE',
        ...params,
      }),

    /**
     * No description
     *
     * @name HocViControllerCreate
     * @request POST:/api/hoc-vi
     */
    hocViCreate: (data: HocViDto, params: RequestParams = {}) =>
      this.request<HocVi, any>({
        path: `/api/hoc-vi`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name HocViControllerFind
     * @request GET:/api/hoc-vi
     */
    hocViFind: (params: RequestParams = {}) =>
      this.request<HocVi[], any>({
        path: `/api/hoc-vi`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name HocViControllerFindById
     * @request GET:/api/hoc-vi/{id}
     */
    hocViFindById: (id: string, params: RequestParams = {}) =>
      this.request<HocVi, any>({
        path: `/api/hoc-vi/${id}`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name HocViControllerUpdate
     * @request PATCH:/api/hoc-vi/{id}
     */
    hocViUpdate: (id: string, data: HocViDto, params: RequestParams = {}) =>
      this.request<HocVi, any>({
        path: `/api/hoc-vi/${id}`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name HocViControllerSoftDelete
     * @request DELETE:/api/hoc-vi/{id}
     */
    hocViSoftDelete: (id: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/hoc-vi/${id}`,
        method: 'DELETE',
        ...params,
      }),

    /**
     * No description
     *
     * @name DanhMucCauHoiControllerFindCap1
     * @request GET:/api/danh-muc-cau-hoi/cap1
     */
    danhMucCauHoiFindCap1: (params: RequestParams = {}) =>
      this.request<DanhMucCauHoi[], any>({
        path: `/api/danh-muc-cau-hoi/cap1`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name DanhMucCauHoiControllerFindCap2
     * @request GET:/api/danh-muc-cau-hoi/cap2
     */
    danhMucCauHoiFindCap2: (params: RequestParams = {}) =>
      this.request<DanhMucCauHoi[], any>({
        path: `/api/danh-muc-cau-hoi/cap2`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name DanhMucCauHoiControllerCreate
     * @request POST:/api/danh-muc-cau-hoi
     */
    danhMucCauHoiCreate: (data: DanhMucCauHoiDto, params: RequestParams = {}) =>
      this.request<DanhMucCauHoi, any>({
        path: `/api/danh-muc-cau-hoi`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name DanhMucCauHoiControllerFind
     * @request GET:/api/danh-muc-cau-hoi
     */
    danhMucCauHoiFind: (params: RequestParams = {}) =>
      this.request<DanhMucCauHoi[], any>({
        path: `/api/danh-muc-cau-hoi`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name DanhMucCauHoiControllerFindById
     * @request GET:/api/danh-muc-cau-hoi/{id}
     */
    danhMucCauHoiFindById: (id: string, params: RequestParams = {}) =>
      this.request<DanhMucCauHoi, any>({
        path: `/api/danh-muc-cau-hoi/${id}`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name DanhMucCauHoiControllerUpdate
     * @request PATCH:/api/danh-muc-cau-hoi/{id}
     */
    danhMucCauHoiUpdate: (id: string, data: DanhMucCauHoiDto, params: RequestParams = {}) =>
      this.request<DanhMucCauHoi, any>({
        path: `/api/danh-muc-cau-hoi/${id}`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name DanhMucCauHoiControllerSoftDelete
     * @request DELETE:/api/danh-muc-cau-hoi/{id}
     */
    danhMucCauHoiSoftDelete: (id: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/danh-muc-cau-hoi/${id}`,
        method: 'DELETE',
        ...params,
      }),

    /**
     * No description
     *
     * @name BoDeControllerCreate
     * @request POST:/api/bo-de
     */
    boDeCreate: (data: BoDeDto, params: RequestParams = {}) =>
      this.request<BoDe, any>({
        path: `/api/bo-de`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name BoDeControllerFind
     * @request GET:/api/bo-de
     */
    boDeFind: (params: RequestParams = {}) =>
      this.request<BoDe[], any>({
        path: `/api/bo-de`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name BoDeControllerFindById
     * @request GET:/api/bo-de/{id}
     */
    boDeFindById: (id: string, params: RequestParams = {}) =>
      this.request<BoDe, any>({
        path: `/api/bo-de/${id}`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name BoDeControllerUpdate
     * @request PATCH:/api/bo-de/{id}
     */
    boDeUpdate: (id: string, data: BoDeDto, params: RequestParams = {}) =>
      this.request<BoDe, any>({
        path: `/api/bo-de/${id}`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name BoDeControllerSoftDelete
     * @request DELETE:/api/bo-de/{id}
     */
    boDeSoftDelete: (id: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/bo-de/${id}`,
        method: 'DELETE',
        ...params,
      }),

    /**
     * No description
     *
     * @name DanhMucTaiLieuControllerCreate
     * @request POST:/api/danh-muc-tai-lieu
     */
    danhMucTaiLieuCreate: (data: TaiLieuDto, params: RequestParams = {}) =>
      this.request<TaiLieu, any>({
        path: `/api/danh-muc-tai-lieu`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name DanhMucTaiLieuControllerFind
     * @request GET:/api/danh-muc-tai-lieu
     */
    danhMucTaiLieuFind: (params: RequestParams = {}) =>
      this.request<TaiLieu[], any>({
        path: `/api/danh-muc-tai-lieu`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name DanhMucTaiLieuControllerFindById
     * @request GET:/api/danh-muc-tai-lieu/{id}
     */
    danhMucTaiLieuFindById: (id: string, params: RequestParams = {}) =>
      this.request<TaiLieu, any>({
        path: `/api/danh-muc-tai-lieu/${id}`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name DanhMucTaiLieuControllerUpdate
     * @request PATCH:/api/danh-muc-tai-lieu/{id}
     */
    danhMucTaiLieuUpdate: (id: string, data: TaiLieuDto, params: RequestParams = {}) =>
      this.request<TaiLieu, any>({
        path: `/api/danh-muc-tai-lieu/${id}`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name DanhMucTaiLieuControllerSoftDelete
     * @request DELETE:/api/danh-muc-tai-lieu/{id}
     */
    danhMucTaiLieuSoftDelete: (id: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/danh-muc-tai-lieu/${id}`,
        method: 'DELETE',
        ...params,
      }),

    /**
     * No description
     *
     * @name PhongBanControllerCreate
     * @request POST:/api/phong-ban
     */
    phongBanCreate: (data: PhongBanDto, params: RequestParams = {}) =>
      this.request<PhongBan, any>({
        path: `/api/phong-ban`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name PhongBanControllerFind
     * @request GET:/api/phong-ban
     */
    phongBanFind: (params: RequestParams = {}) =>
      this.request<PhongBan[], any>({
        path: `/api/phong-ban`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name PhongBanControllerFindById
     * @request GET:/api/phong-ban/{id}
     */
    phongBanFindById: (id: string, params: RequestParams = {}) =>
      this.request<PhongBan, any>({
        path: `/api/phong-ban/${id}`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name PhongBanControllerUpdate
     * @request PATCH:/api/phong-ban/{id}
     */
    phongBanUpdate: (id: string, data: PhongBanDto, params: RequestParams = {}) =>
      this.request<PhongBan, any>({
        path: `/api/phong-ban/${id}`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name PhongBanControllerSoftDelete
     * @request DELETE:/api/phong-ban/{id}
     */
    phongBanSoftDelete: (id: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/phong-ban/${id}`,
        method: 'DELETE',
        ...params,
      }),

    /**
     * No description
     *
     * @name LinhVuHoatDongControllerCreate
     * @request POST:/api/linh-vu-hoat-dong
     */
    linhVuHoatDongCreate: (data: LinhVucHoatDongDto, params: RequestParams = {}) =>
      this.request<LinhVucHoatDong, any>({
        path: `/api/linh-vu-hoat-dong`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name LinhVuHoatDongControllerFind
     * @request GET:/api/linh-vu-hoat-dong
     */
    linhVuHoatDongFind: (params: RequestParams = {}) =>
      this.request<LinhVucHoatDong[], any>({
        path: `/api/linh-vu-hoat-dong`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name LinhVuHoatDongControllerFindById
     * @request GET:/api/linh-vu-hoat-dong/{id}
     */
    linhVuHoatDongFindById: (id: string, params: RequestParams = {}) =>
      this.request<LinhVucHoatDong, any>({
        path: `/api/linh-vu-hoat-dong/${id}`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name LinhVuHoatDongControllerUpdate
     * @request PATCH:/api/linh-vu-hoat-dong/{id}
     */
    linhVuHoatDongUpdate: (id: string, data: LinhVucHoatDongDto, params: RequestParams = {}) =>
      this.request<LinhVucHoatDong, any>({
        path: `/api/linh-vu-hoat-dong/${id}`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name LinhVuHoatDongControllerSoftDelete
     * @request DELETE:/api/linh-vu-hoat-dong/{id}
     */
    linhVuHoatDongSoftDelete: (id: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/linh-vu-hoat-dong/${id}`,
        method: 'DELETE',
        ...params,
      }),

    /**
     * No description
     *
     * @name PhieuThuChiControllerCreate
     * @request POST:/api/phieu-thu-chi
     */
    phieuThuChiCreate: (data: PhieuThuChiDto, params: RequestParams = {}) =>
      this.request<PhieuThuChi, any>({
        path: `/api/phieu-thu-chi`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name PhieuThuChiControllerFind
     * @request GET:/api/phieu-thu-chi
     */
    phieuThuChiFind: (params: RequestParams = {}) =>
      this.request<PhieuThuChi[], any>({
        path: `/api/phieu-thu-chi`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name PhieuThuChiControllerFindById
     * @request GET:/api/phieu-thu-chi/{id}
     */
    phieuThuChiFindById: (id: string, params: RequestParams = {}) =>
      this.request<PhieuThuChi, any>({
        path: `/api/phieu-thu-chi/${id}`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name PhieuThuChiControllerUpdate
     * @request PATCH:/api/phieu-thu-chi/{id}
     */
    phieuThuChiUpdate: (id: string, data: PhieuThuChiDto, params: RequestParams = {}) =>
      this.request<PhieuThuChi, any>({
        path: `/api/phieu-thu-chi/${id}`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name PhieuThuChiControllerSoftDelete
     * @request DELETE:/api/phieu-thu-chi/{id}
     */
    phieuThuChiSoftDelete: (id: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/phieu-thu-chi/${id}`,
        method: 'DELETE',
        ...params,
      }),

    /**
     * No description
     *
     * @name ThongBaoControllerGetMyNotification
     * @request GET:/api/thong-bao/me
     */
    thongBaoGetMyNotification: (params: RequestParams = {}) =>
      this.request<ThongBao[], any>({
        path: `/api/thong-bao/me`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name ThongBaoControllerDaDoc
     * @request POST:/api/thong-bao/da-doc
     */
    thongBaoDaDoc: (data: DaDocTBDto, params: RequestParams = {}) =>
      this.request<ThongBao, any>({
        path: `/api/thong-bao/da-doc`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name ThongBaoControllerCreate
     * @request POST:/api/thong-bao
     */
    thongBaoCreate: (data: ThongBaoDto, params: RequestParams = {}) =>
      this.request<ThongBao, any>({
        path: `/api/thong-bao`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name ThongBaoControllerFind
     * @request GET:/api/thong-bao
     */
    thongBaoFind: (params: RequestParams = {}) =>
      this.request<ThongBao[], any>({
        path: `/api/thong-bao`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name ThongBaoControllerFindById
     * @request GET:/api/thong-bao/{id}
     */
    thongBaoFindById: (id: string, params: RequestParams = {}) =>
      this.request<ThongBao, any>({
        path: `/api/thong-bao/${id}`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name ThongBaoControllerUpdate
     * @request PATCH:/api/thong-bao/{id}
     */
    thongBaoUpdate: (id: string, data: ThongBaoDto, params: RequestParams = {}) =>
      this.request<ThongBao, any>({
        path: `/api/thong-bao/${id}`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name ThongBaoControllerSoftDelete
     * @request DELETE:/api/thong-bao/{id}
     */
    thongBaoSoftDelete: (id: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/thong-bao/${id}`,
        method: 'DELETE',
        ...params,
      }),

    /**
     * No description
     *
     * @name BaiVietControllerCreate
     * @request POST:/api/bai-viet
     */
    baiVietCreate: (data: BaiVietDto, params: RequestParams = {}) =>
      this.request<BaiViet, any>({
        path: `/api/bai-viet`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name BaiVietControllerFind
     * @request GET:/api/bai-viet
     */
    baiVietFind: (params: RequestParams = {}) =>
      this.request<BaiViet[], any>({
        path: `/api/bai-viet`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name BaiVietControllerFindById
     * @request GET:/api/bai-viet/{id}
     */
    baiVietFindById: (id: string, params: RequestParams = {}) =>
      this.request<BaiViet, any>({
        path: `/api/bai-viet/${id}`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name BaiVietControllerUpdate
     * @request PATCH:/api/bai-viet/{id}
     */
    baiVietUpdate: (id: string, data: BaiVietDto, params: RequestParams = {}) =>
      this.request<BaiViet, any>({
        path: `/api/bai-viet/${id}`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name BaiVietControllerSoftDelete
     * @request DELETE:/api/bai-viet/{id}
     */
    baiVietSoftDelete: (id: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/bai-viet/${id}`,
        method: 'DELETE',
        ...params,
      }),

    /**
     * No description
     *
     * @name DoiTacControllerCreate
     * @request POST:/api/doi-tac
     */
    doiTacCreate: (data: DoiTacDto, params: RequestParams = {}) =>
      this.request<DoiTac, any>({
        path: `/api/doi-tac`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name DoiTacControllerFind
     * @request GET:/api/doi-tac
     */
    doiTacFind: (params: RequestParams = {}) =>
      this.request<DoiTac[], any>({
        path: `/api/doi-tac`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name DoiTacControllerFindById
     * @request GET:/api/doi-tac/{id}
     */
    doiTacFindById: (id: string, params: RequestParams = {}) =>
      this.request<DoiTac, any>({
        path: `/api/doi-tac/${id}`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name DoiTacControllerUpdate
     * @request PATCH:/api/doi-tac/{id}
     */
    doiTacUpdate: (id: string, data: DoiTacDto, params: RequestParams = {}) =>
      this.request<DoiTac, any>({
        path: `/api/doi-tac/${id}`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name DoiTacControllerSoftDelete
     * @request DELETE:/api/doi-tac/{id}
     */
    doiTacSoftDelete: (id: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/doi-tac/${id}`,
        method: 'DELETE',
        ...params,
      }),

    /**
     * No description
     *
     * @name CongVanDiControllerCreate
     * @request POST:/api/cong-van-di
     */
    congVanDiCreate: (data: CongVanDiDto, params: RequestParams = {}) =>
      this.request<CongVanDi, any>({
        path: `/api/cong-van-di`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name CongVanDiControllerFind
     * @request GET:/api/cong-van-di
     */
    congVanDiFind: (params: RequestParams = {}) =>
      this.request<CongVanDi[], any>({
        path: `/api/cong-van-di`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name CongVanDiControllerFindById
     * @request GET:/api/cong-van-di/{id}
     */
    congVanDiFindById: (id: string, params: RequestParams = {}) =>
      this.request<CongVanDi, any>({
        path: `/api/cong-van-di/${id}`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name CongVanDiControllerUpdate
     * @request PATCH:/api/cong-van-di/{id}
     */
    congVanDiUpdate: (id: string, data: CongVanDiDto, params: RequestParams = {}) =>
      this.request<CongVanDi, any>({
        path: `/api/cong-van-di/${id}`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name CongVanDiControllerSoftDelete
     * @request DELETE:/api/cong-van-di/{id}
     */
    congVanDiSoftDelete: (id: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/cong-van-di/${id}`,
        method: 'DELETE',
        ...params,
      }),

    /**
     * No description
     *
     * @name CongVanDenControllerCreate
     * @request POST:/api/cong-van-den
     */
    congVanDenCreate: (data: CongVanDenDto, params: RequestParams = {}) =>
      this.request<CongVanDen, any>({
        path: `/api/cong-van-den`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name CongVanDenControllerFind
     * @request GET:/api/cong-van-den
     */
    congVanDenFind: (params: RequestParams = {}) =>
      this.request<CongVanDen[], any>({
        path: `/api/cong-van-den`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name CongVanDenControllerFindById
     * @request GET:/api/cong-van-den/{id}
     */
    congVanDenFindById: (id: string, params: RequestParams = {}) =>
      this.request<CongVanDen, any>({
        path: `/api/cong-van-den/${id}`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name CongVanDenControllerUpdate
     * @request PATCH:/api/cong-van-den/{id}
     */
    congVanDenUpdate: (id: string, data: CongVanDenDto, params: RequestParams = {}) =>
      this.request<CongVanDen, any>({
        path: `/api/cong-van-den/${id}`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name CongVanDenControllerSoftDelete
     * @request DELETE:/api/cong-van-den/{id}
     */
    congVanDenSoftDelete: (id: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/cong-van-den/${id}`,
        method: 'DELETE',
        ...params,
      }),

    /**
     * No description
     *
     * @name DanhMucThuChiControllerCreate
     * @request POST:/api/danh-muc-thu-chi
     */
    danhMucThuChiCreate: (data: DanhMucThuChiDto, params: RequestParams = {}) =>
      this.request<DanhMucThuChi, any>({
        path: `/api/danh-muc-thu-chi`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name DanhMucThuChiControllerFind
     * @request GET:/api/danh-muc-thu-chi
     */
    danhMucThuChiFind: (params: RequestParams = {}) =>
      this.request<DanhMucThuChi[], any>({
        path: `/api/danh-muc-thu-chi`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name DanhMucThuChiControllerFindById
     * @request GET:/api/danh-muc-thu-chi/{id}
     */
    danhMucThuChiFindById: (id: string, params: RequestParams = {}) =>
      this.request<DanhMucThuChi, any>({
        path: `/api/danh-muc-thu-chi/${id}`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name DanhMucThuChiControllerUpdate
     * @request PATCH:/api/danh-muc-thu-chi/{id}
     */
    danhMucThuChiUpdate: (id: string, data: DanhMucThuChiDto, params: RequestParams = {}) =>
      this.request<DanhMucThuChi, any>({
        path: `/api/danh-muc-thu-chi/${id}`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name DanhMucThuChiControllerSoftDelete
     * @request DELETE:/api/danh-muc-thu-chi/{id}
     */
    danhMucThuChiSoftDelete: (id: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/danh-muc-thu-chi/${id}`,
        method: 'DELETE',
        ...params,
      }),

    /**
     * No description
     *
     * @name CauHoiControllerFindAll
     * @request GET:/api/cau-hoi/all
     */
    cauHoiFindAll: (params: RequestParams = {}) =>
      this.request<CauHoi[], any>({
        path: `/api/cau-hoi/all`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name CauHoiControllerCreate
     * @request POST:/api/cau-hoi
     */
    cauHoiCreate: (data: CauHoiDto, params: RequestParams = {}) =>
      this.request<CauHoi, any>({
        path: `/api/cau-hoi`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name CauHoiControllerFind
     * @request GET:/api/cau-hoi
     */
    cauHoiFind: (params: RequestParams = {}) =>
      this.request<CauHoi[], any>({
        path: `/api/cau-hoi`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name CauHoiControllerFindById
     * @request GET:/api/cau-hoi/{id}
     */
    cauHoiFindById: (id: string, params: RequestParams = {}) =>
      this.request<CauHoi, any>({
        path: `/api/cau-hoi/${id}`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name CauHoiControllerUpdate
     * @request PATCH:/api/cau-hoi/{id}
     */
    cauHoiUpdate: (id: string, data: CauHoiDto, params: RequestParams = {}) =>
      this.request<CauHoi, any>({
        path: `/api/cau-hoi/${id}`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name CauHoiControllerSoftDelete
     * @request DELETE:/api/cau-hoi/{id}
     */
    cauHoiSoftDelete: (id: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/cau-hoi/${id}`,
        method: 'DELETE',
        ...params,
      }),

    /**
     * No description
     *
     * @name QuanLyBoDeControllerGetBoDeThiCauHoi
     * @request GET:/api/quan-ly-bo-de/cau-hoi/{id}
     */
    quanLyBoDeGetBoDeThiCauHoi: (id: number, params: RequestParams = {}) =>
      this.request<QuanLyBoDe, any>({
        path: `/api/quan-ly-bo-de/cau-hoi/${id}`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name QuanLyBoDeControllerCreate
     * @request POST:/api/quan-ly-bo-de
     */
    quanLyBoDeCreate: (data: QuanLyBoDeDto, params: RequestParams = {}) =>
      this.request<QuanLyBoDe, any>({
        path: `/api/quan-ly-bo-de`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name QuanLyBoDeControllerFind
     * @request GET:/api/quan-ly-bo-de
     */
    quanLyBoDeFind: (params: RequestParams = {}) =>
      this.request<QuanLyBoDe[], any>({
        path: `/api/quan-ly-bo-de`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name QuanLyBoDeControllerFindById
     * @request GET:/api/quan-ly-bo-de/{id}
     */
    quanLyBoDeFindById: (id: string, params: RequestParams = {}) =>
      this.request<QuanLyBoDe, any>({
        path: `/api/quan-ly-bo-de/${id}`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name QuanLyBoDeControllerUpdate
     * @request PATCH:/api/quan-ly-bo-de/{id}
     */
    quanLyBoDeUpdate: (id: string, data: QuanLyBoDeDto, params: RequestParams = {}) =>
      this.request<QuanLyBoDe, any>({
        path: `/api/quan-ly-bo-de/${id}`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name QuanLyBoDeControllerSoftDelete
     * @request DELETE:/api/quan-ly-bo-de/{id}
     */
    quanLyBoDeSoftDelete: (id: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/quan-ly-bo-de/${id}`,
        method: 'DELETE',
        ...params,
      }),

    /**
     * No description
     *
     * @name TaiLieuHocTapControllerConfirmVideo
     * @request PATCH:/api/tai-lieu-hoc-tap/confirm-video/{id}
     */
    taiLieuHocTapConfirmVideo: (
      id: number,
      query: {
        soGioHoc: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/api/tai-lieu-hoc-tap/confirm-video/${id}`,
        method: 'PATCH',
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @name TaiLieuHocTapControllerDetail
     * @request GET:/api/tai-lieu-hoc-tap/public/{id}
     */
    taiLieuHocTapDetail: (id: string, params: RequestParams = {}) =>
      this.request<TaiLieuHocTap, any>({
        path: `/api/tai-lieu-hoc-tap/public/${id}`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name TaiLieuHocTapControllerCreate
     * @request POST:/api/tai-lieu-hoc-tap
     */
    taiLieuHocTapCreate: (data: TaiLieuHocTapDto, params: RequestParams = {}) =>
      this.request<TaiLieuHocTap, any>({
        path: `/api/tai-lieu-hoc-tap`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name TaiLieuHocTapControllerFind
     * @request GET:/api/tai-lieu-hoc-tap
     */
    taiLieuHocTapFind: (params: RequestParams = {}) =>
      this.request<TaiLieuHocTap[], any>({
        path: `/api/tai-lieu-hoc-tap`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name TaiLieuHocTapControllerFindById
     * @request GET:/api/tai-lieu-hoc-tap/{id}
     */
    taiLieuHocTapFindById: (id: string, params: RequestParams = {}) =>
      this.request<TaiLieuHocTap, any>({
        path: `/api/tai-lieu-hoc-tap/${id}`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name TaiLieuHocTapControllerUpdate
     * @request PATCH:/api/tai-lieu-hoc-tap/{id}
     */
    taiLieuHocTapUpdate: (id: string, data: TaiLieuHocTapDto, params: RequestParams = {}) =>
      this.request<TaiLieuHocTap, any>({
        path: `/api/tai-lieu-hoc-tap/${id}`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name TaiLieuHocTapControllerSoftDelete
     * @request DELETE:/api/tai-lieu-hoc-tap/{id}
     */
    taiLieuHocTapSoftDelete: (id: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/tai-lieu-hoc-tap/${id}`,
        method: 'DELETE',
        ...params,
      }),

    /**
     * No description
     *
     * @name TietHocOnlineControllerCreate
     * @request POST:/api/tiet-hoc-online
     */
    tietHocOnlineCreate: (data: TietHocOnlineDto, params: RequestParams = {}) =>
      this.request<TietHocOnline, any>({
        path: `/api/tiet-hoc-online`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name TietHocOnlineControllerFind
     * @request GET:/api/tiet-hoc-online
     */
    tietHocOnlineFind: (params: RequestParams = {}) =>
      this.request<TietHocOnline[], any>({
        path: `/api/tiet-hoc-online`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name TietHocOnlineControllerFindById
     * @request GET:/api/tiet-hoc-online/{id}
     */
    tietHocOnlineFindById: (id: string, params: RequestParams = {}) =>
      this.request<TietHocOnline, any>({
        path: `/api/tiet-hoc-online/${id}`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name TietHocOnlineControllerUpdate
     * @request PATCH:/api/tiet-hoc-online/{id}
     */
    tietHocOnlineUpdate: (id: string, data: TietHocOnlineDto, params: RequestParams = {}) =>
      this.request<TietHocOnline, any>({
        path: `/api/tiet-hoc-online/${id}`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name TietHocOnlineControllerSoftDelete
     * @request DELETE:/api/tiet-hoc-online/{id}
     */
    tietHocOnlineSoftDelete: (id: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/tiet-hoc-online/${id}`,
        method: 'DELETE',
        ...params,
      }),

    /**
     * No description
     *
     * @name QuanLyTaiLieuHocTapControllerCreate
     * @request POST:/api/quan-ly-tai-lieu-hoc-tap
     */
    quanLyTaiLieuHocTapCreate: (data: QuanLyTaiLieuDto, params: RequestParams = {}) =>
      this.request<QuanLyTaiLieuHocTap, any>({
        path: `/api/quan-ly-tai-lieu-hoc-tap`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name QuanLyTaiLieuHocTapControllerFind
     * @request GET:/api/quan-ly-tai-lieu-hoc-tap
     */
    quanLyTaiLieuHocTapFind: (params: RequestParams = {}) =>
      this.request<QuanLyTaiLieuHocTap[], any>({
        path: `/api/quan-ly-tai-lieu-hoc-tap`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name QuanLyTaiLieuHocTapControllerFindById
     * @request GET:/api/quan-ly-tai-lieu-hoc-tap/{id}
     */
    quanLyTaiLieuHocTapFindById: (id: string, params: RequestParams = {}) =>
      this.request<QuanLyTaiLieuHocTap, any>({
        path: `/api/quan-ly-tai-lieu-hoc-tap/${id}`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name QuanLyTaiLieuHocTapControllerUpdate
     * @request PATCH:/api/quan-ly-tai-lieu-hoc-tap/{id}
     */
    quanLyTaiLieuHocTapUpdate: (id: string, data: QuanLyTaiLieuDto, params: RequestParams = {}) =>
      this.request<QuanLyTaiLieuHocTap, any>({
        path: `/api/quan-ly-tai-lieu-hoc-tap/${id}`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name QuanLyTaiLieuHocTapControllerSoftDelete
     * @request DELETE:/api/quan-ly-tai-lieu-hoc-tap/{id}
     */
    quanLyTaiLieuHocTapSoftDelete: (id: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/quan-ly-tai-lieu-hoc-tap/${id}`,
        method: 'DELETE',
        ...params,
      }),

    /**
     * No description
     *
     * @name BoPhanBanHanhControllerCreate
     * @request POST:/api/bo-phan-ban-hanh
     */
    boPhanBanHanhCreate: (data: BoPhanBanHanhDto, params: RequestParams = {}) =>
      this.request<BoPhanBanHanh, any>({
        path: `/api/bo-phan-ban-hanh`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name BoPhanBanHanhControllerFind
     * @request GET:/api/bo-phan-ban-hanh
     */
    boPhanBanHanhFind: (params: RequestParams = {}) =>
      this.request<BoPhanBanHanh[], any>({
        path: `/api/bo-phan-ban-hanh`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name BoPhanBanHanhControllerFindById
     * @request GET:/api/bo-phan-ban-hanh/{id}
     */
    boPhanBanHanhFindById: (id: string, params: RequestParams = {}) =>
      this.request<BoPhanBanHanh, any>({
        path: `/api/bo-phan-ban-hanh/${id}`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name BoPhanBanHanhControllerUpdate
     * @request PATCH:/api/bo-phan-ban-hanh/{id}
     */
    boPhanBanHanhUpdate: (id: string, data: BoPhanBanHanhDto, params: RequestParams = {}) =>
      this.request<BoPhanBanHanh, any>({
        path: `/api/bo-phan-ban-hanh/${id}`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name BoPhanBanHanhControllerSoftDelete
     * @request DELETE:/api/bo-phan-ban-hanh/{id}
     */
    boPhanBanHanhSoftDelete: (id: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/bo-phan-ban-hanh/${id}`,
        method: 'DELETE',
        ...params,
      }),

    /**
     * No description
     *
     * @name LoaiCongVanDiControllerCreate
     * @request POST:/api/loai-cong-van-di
     */
    loaiCongVanDiCreate: (data: LoaiCongVanDiDto, params: RequestParams = {}) =>
      this.request<LoaiCongVanDi, any>({
        path: `/api/loai-cong-van-di`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name LoaiCongVanDiControllerFind
     * @request GET:/api/loai-cong-van-di
     */
    loaiCongVanDiFind: (params: RequestParams = {}) =>
      this.request<LoaiCongVanDi[], any>({
        path: `/api/loai-cong-van-di`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name LoaiCongVanDiControllerFindById
     * @request GET:/api/loai-cong-van-di/{id}
     */
    loaiCongVanDiFindById: (id: string, params: RequestParams = {}) =>
      this.request<LoaiCongVanDi, any>({
        path: `/api/loai-cong-van-di/${id}`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name LoaiCongVanDiControllerUpdate
     * @request PATCH:/api/loai-cong-van-di/{id}
     */
    loaiCongVanDiUpdate: (id: string, data: LoaiCongVanDiDto, params: RequestParams = {}) =>
      this.request<LoaiCongVanDi, any>({
        path: `/api/loai-cong-van-di/${id}`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name LoaiCongVanDiControllerSoftDelete
     * @request DELETE:/api/loai-cong-van-di/{id}
     */
    loaiCongVanDiSoftDelete: (id: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/loai-cong-van-di/${id}`,
        method: 'DELETE',
        ...params,
      }),

    /**
     * No description
     *
     * @name AboutControllerCreate
     * @request POST:/api/about
     */
    aboutCreate: (data: AboutDto, params: RequestParams = {}) =>
      this.request<About, any>({
        path: `/api/about`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name AboutControllerFind
     * @request GET:/api/about
     */
    aboutFind: (params: RequestParams = {}) =>
      this.request<About[], any>({
        path: `/api/about`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name AboutControllerFindById
     * @request GET:/api/about/{id}
     */
    aboutFindById: (id: string, params: RequestParams = {}) =>
      this.request<About, any>({
        path: `/api/about/${id}`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name AboutControllerUpdate
     * @request PATCH:/api/about/{id}
     */
    aboutUpdate: (id: string, data: AboutDto, params: RequestParams = {}) =>
      this.request<About, any>({
        path: `/api/about/${id}`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name AboutControllerSoftDelete
     * @request DELETE:/api/about/{id}
     */
    aboutSoftDelete: (id: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/about/${id}`,
        method: 'DELETE',
        ...params,
      }),

    /**
     * No description
     *
     * @name ThongTinControllerCreate
     * @request POST:/api/thong-tin
     */
    thongTinCreate: (data: ThongTinDto, params: RequestParams = {}) =>
      this.request<ThongTin, any>({
        path: `/api/thong-tin`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name ThongTinControllerFind
     * @request GET:/api/thong-tin
     */
    thongTinFind: (params: RequestParams = {}) =>
      this.request<ThongTin[], any>({
        path: `/api/thong-tin`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name ThongTinControllerFindById
     * @request GET:/api/thong-tin/{id}
     */
    thongTinFindById: (id: string, params: RequestParams = {}) =>
      this.request<ThongTin, any>({
        path: `/api/thong-tin/${id}`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name ThongTinControllerUpdate
     * @request PATCH:/api/thong-tin/{id}
     */
    thongTinUpdate: (id: string, data: ThongTinDto, params: RequestParams = {}) =>
      this.request<ThongTin, any>({
        path: `/api/thong-tin/${id}`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name ThongTinControllerSoftDelete
     * @request DELETE:/api/thong-tin/{id}
     */
    thongTinSoftDelete: (id: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/thong-tin/${id}`,
        method: 'DELETE',
        ...params,
      }),

    /**
     * No description
     *
     * @name SupportControllerCreate
     * @request POST:/api/support
     */
    supportCreate: (data: SupportDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/support`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @name SupportControllerFind
     * @request GET:/api/support
     */
    supportFind: (params: RequestParams = {}) =>
      this.request<Support[], any>({
        path: `/api/support`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name SupportControllerFindById
     * @request GET:/api/support/{id}
     */
    supportFindById: (id: string, params: RequestParams = {}) =>
      this.request<Support, any>({
        path: `/api/support/${id}`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name SupportControllerUpdate
     * @request PATCH:/api/support/{id}
     */
    supportUpdate: (id: string, data: SupportDto, params: RequestParams = {}) =>
      this.request<Support, any>({
        path: `/api/support/${id}`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name SupportControllerSoftDelete
     * @request DELETE:/api/support/{id}
     */
    supportSoftDelete: (id: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/support/${id}`,
        method: 'DELETE',
        ...params,
      }),

    /**
     * No description
     *
     * @name FaqControllerCreate
     * @request POST:/api/faq
     */
    faqCreate: (data: FAQDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/faq`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @name FaqControllerFind
     * @request GET:/api/faq
     */
    faqFind: (params: RequestParams = {}) =>
      this.request<FAQ[], any>({
        path: `/api/faq`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name FaqControllerFindById
     * @request GET:/api/faq/{id}
     */
    faqFindById: (id: string, params: RequestParams = {}) =>
      this.request<FAQ, any>({
        path: `/api/faq/${id}`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name FaqControllerUpdate
     * @request PATCH:/api/faq/{id}
     */
    faqUpdate: (id: string, data: FAQDto, params: RequestParams = {}) =>
      this.request<FAQ, any>({
        path: `/api/faq/${id}`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name FaqControllerSoftDelete
     * @request DELETE:/api/faq/{id}
     */
    faqSoftDelete: (id: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/faq/${id}`,
        method: 'DELETE',
        ...params,
      }),

    /**
     * No description
     *
     * @name CauHoiHocTapControllerGetCauHoiHocTapByKhoaHoc
     * @request GET:/api/cau-hoi-hoc-tap/cau-hoi-hoc-tap/khoa-hoc
     */
    cauHoiHocTapGetCauHoiHocTapByKhoaHoc: (
      query: {
        khoaHocId: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<CauHoiHocTap[], any>({
        path: `/api/cau-hoi-hoc-tap/cau-hoi-hoc-tap/khoa-hoc`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name CauHoiHocTapControllerFindByFilter
     * @request GET:/api/cau-hoi-hoc-tap/cau-hoi-hoc-tap
     */
    cauHoiHocTapFindByFilter: (
      query: {
        danhMucId: number;
        tenKhoaHoc: string;
        tuNgay: string;
        denNgay: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<CauHoiHocTap[], any>({
        path: `/api/cau-hoi-hoc-tap/cau-hoi-hoc-tap`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name CauHoiHocTapControllerGuiCauHoi
     * @request POST:/api/cau-hoi-hoc-tap/cau-hoi-hoc-tap
     */
    cauHoiHocTapGuiCauHoi: (
      query: {
        khoaHocId: number;
      },
      data: CauHoiHocTapDto,
      params: RequestParams = {},
    ) =>
      this.request<CauHoiHocTap[], any>({
        path: `/api/cau-hoi-hoc-tap/cau-hoi-hoc-tap`,
        method: 'POST',
        query: query,
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name CauHoiHocTapControllerTraLoi
     * @request POST:/api/cau-hoi-hoc-tap/cau-hoi-hoc-tap/{id}
     */
    cauHoiHocTapTraLoi: (id: number, data: CauHoiHocTapDto, params: RequestParams = {}) =>
      this.request<CauHoiHocTap, any>({
        path: `/api/cau-hoi-hoc-tap/cau-hoi-hoc-tap/${id}`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name CauHoiHocTapControllerDelete
     * @request DELETE:/api/cau-hoi-hoc-tap/cau-hoi-hoc-tap/{id}
     */
    cauHoiHocTapDelete: (id: number, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/cau-hoi-hoc-tap/cau-hoi-hoc-tap/${id}`,
        method: 'DELETE',
        ...params,
      }),

    /**
     * No description
     *
     * @name CauHoiHocTapControllerFindById
     * @request GET:/api/cau-hoi-hoc-tap/cau-hoi-hoc-tap/{id}
     */
    cauHoiHocTapFindById: (id: number, params: RequestParams = {}) =>
      this.request<CauHoiHocTap, any>({
        path: `/api/cau-hoi-hoc-tap/cau-hoi-hoc-tap/${id}`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags quang-cao
     * @name QuangCaoControllerCreate
     * @request POST:/api/quang-cao
     */
    quangCaoCreate: (data: QuangCaoDto, params: RequestParams = {}) =>
      this.request<QuangCao, any>({
        path: `/api/quang-cao`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags quang-cao
     * @name QuangCaoControllerFind
     * @request GET:/api/quang-cao
     */
    quangCaoFind: (params: RequestParams = {}) =>
      this.request<QuangCao[], any>({
        path: `/api/quang-cao`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags quang-cao
     * @name QuangCaoControllerFindById
     * @request GET:/api/quang-cao/{id}
     */
    quangCaoFindById: (id: string, params: RequestParams = {}) =>
      this.request<QuangCao, any>({
        path: `/api/quang-cao/${id}`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags quang-cao
     * @name QuangCaoControllerUpdate
     * @request PATCH:/api/quang-cao/{id}
     */
    quangCaoUpdate: (id: string, data: QuangCaoDto, params: RequestParams = {}) =>
      this.request<QuangCao, any>({
        path: `/api/quang-cao/${id}`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags quang-cao
     * @name QuangCaoControllerSoftDelete
     * @request DELETE:/api/quang-cao/{id}
     */
    quangCaoSoftDelete: (id: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/quang-cao/${id}`,
        method: 'DELETE',
        ...params,
      }),

    /**
     * No description
     *
     * @name ChuyenKhoaControllerCreate
     * @request POST:/api/chuyen-khoa
     */
    chuyenKhoaCreate: (data: ChuyenKhoaDto, params: RequestParams = {}) =>
      this.request<ChuyenKhoa, any>({
        path: `/api/chuyen-khoa`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name ChuyenKhoaControllerFind
     * @request GET:/api/chuyen-khoa
     */
    chuyenKhoaFind: (params: RequestParams = {}) =>
      this.request<ChuyenKhoa[], any>({
        path: `/api/chuyen-khoa`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name ChuyenKhoaControllerFindById
     * @request GET:/api/chuyen-khoa/{id}
     */
    chuyenKhoaFindById: (id: string, params: RequestParams = {}) =>
      this.request<ChuyenKhoa, any>({
        path: `/api/chuyen-khoa/${id}`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name ChuyenKhoaControllerUpdate
     * @request PATCH:/api/chuyen-khoa/{id}
     */
    chuyenKhoaUpdate: (id: string, data: ChuyenKhoaDto, params: RequestParams = {}) =>
      this.request<ChuyenKhoa, any>({
        path: `/api/chuyen-khoa/${id}`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name ChuyenKhoaControllerSoftDelete
     * @request DELETE:/api/chuyen-khoa/{id}
     */
    chuyenKhoaSoftDelete: (id: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/chuyen-khoa/${id}`,
        method: 'DELETE',
        ...params,
      }),

    /**
     * No description
     *
     * @name QuanLyHocPhiControllerCreate
     * @request POST:/api/quan-ly-hoc-phi
     */
    quanLyHocPhiCreate: (data: QuanLyHocPhiDto, params: RequestParams = {}) =>
      this.request<QuanLyHocPhi, any>({
        path: `/api/quan-ly-hoc-phi`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name QuanLyHocPhiControllerFind
     * @request GET:/api/quan-ly-hoc-phi
     */
    quanLyHocPhiFind: (params: RequestParams = {}) =>
      this.request<QuanLyHocPhi[], any>({
        path: `/api/quan-ly-hoc-phi`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name QuanLyHocPhiControllerFindById
     * @request GET:/api/quan-ly-hoc-phi/{id}
     */
    quanLyHocPhiFindById: (id: string, params: RequestParams = {}) =>
      this.request<QuanLyHocPhi, any>({
        path: `/api/quan-ly-hoc-phi/${id}`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name QuanLyHocPhiControllerUpdate
     * @request PATCH:/api/quan-ly-hoc-phi/{id}
     */
    quanLyHocPhiUpdate: (id: string, data: QuanLyHocPhiDto, params: RequestParams = {}) =>
      this.request<QuanLyHocPhi, any>({
        path: `/api/quan-ly-hoc-phi/${id}`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name QuanLyHocPhiControllerSoftDelete
     * @request DELETE:/api/quan-ly-hoc-phi/{id}
     */
    quanLyHocPhiSoftDelete: (id: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/quan-ly-hoc-phi/${id}`,
        method: 'DELETE',
        ...params,
      }),

    /**
     * No description
     *
     * @name HoiTruongControllerCreate
     * @request POST:/api/hoi-truong
     */
    hoiTruongCreate: (data: HoiTruongDto, params: RequestParams = {}) =>
      this.request<HoiTruong, any>({
        path: `/api/hoi-truong`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name HoiTruongControllerFind
     * @request GET:/api/hoi-truong
     */
    hoiTruongFind: (params: RequestParams = {}) =>
      this.request<HoiTruong[], any>({
        path: `/api/hoi-truong`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name HoiTruongControllerFindById
     * @request GET:/api/hoi-truong/{id}
     */
    hoiTruongFindById: (id: string, params: RequestParams = {}) =>
      this.request<HoiTruong, any>({
        path: `/api/hoi-truong/${id}`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name HoiTruongControllerUpdate
     * @request PATCH:/api/hoi-truong/{id}
     */
    hoiTruongUpdate: (id: string, data: HoiTruongDto, params: RequestParams = {}) =>
      this.request<HoiTruong, any>({
        path: `/api/hoi-truong/${id}`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name HoiTruongControllerSoftDelete
     * @request DELETE:/api/hoi-truong/{id}
     */
    hoiTruongSoftDelete: (id: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/hoi-truong/${id}`,
        method: 'DELETE',
        ...params,
      }),

    /**
     * No description
     *
     * @name ChuToaControllerCreate
     * @request POST:/api/chu-toa
     */
    chuToaCreate: (data: ChuToaDto, params: RequestParams = {}) =>
      this.request<ChuToa, any>({
        path: `/api/chu-toa`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name ChuToaControllerFind
     * @request GET:/api/chu-toa
     */
    chuToaFind: (params: RequestParams = {}) =>
      this.request<ChuToa[], any>({
        path: `/api/chu-toa`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name ChuToaControllerFindById
     * @request GET:/api/chu-toa/{id}
     */
    chuToaFindById: (id: string, params: RequestParams = {}) =>
      this.request<ChuToa, any>({
        path: `/api/chu-toa/${id}`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name ChuToaControllerUpdate
     * @request PATCH:/api/chu-toa/{id}
     */
    chuToaUpdate: (id: string, data: ChuToaDto, params: RequestParams = {}) =>
      this.request<ChuToa, any>({
        path: `/api/chu-toa/${id}`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name ChuToaControllerSoftDelete
     * @request DELETE:/api/chu-toa/{id}
     */
    chuToaSoftDelete: (id: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/chu-toa/${id}`,
        method: 'DELETE',
        ...params,
      }),

    /**
     * No description
     *
     * @name DashboardControllerGetDashboard
     * @request GET:/api/dashboard
     */
    dashboardGetDashboard: (params: RequestParams = {}) =>
      this.request<DashboardDto, any>({
        path: `/api/dashboard`,
        method: 'GET',
        format: 'json',
        ...params,
      }),
  };
}
